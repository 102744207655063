import { UserInfoDTO } from '@dto/user-info-dto';
import { merge, Observable, of, shareReplay, Subject, throttleTime } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { anonymousUser } from '@cms/services/security.service';

export class AnonymousUserInfoCache {
  constructor(protected fetchFn: () => Observable<UserInfoDTO>) {}

  getMe(): Observable<Partial<UserInfoDTO>> {
    return of({ id: -1, name: 'anonymous', role: 'ROLE_ANONYMOUS' });
  }

  setMe(value: Partial<UserInfoDTO>) {}
}

export class UserInfoCache extends AnonymousUserInfoCache {
  private me!: Partial<UserInfoDTO>;

  private onCacheRefreshed?: (me: Partial<UserInfoDTO>) => void;
  private lastUpdated = Date.now();

  constructor(fetchFn: () => Observable<UserInfoDTO>) {
    super(fetchFn);
  }

  private activeRefresh?: Promise<Partial<UserInfoDTO>>;

  private refreshCache(): Promise<Partial<UserInfoDTO>> {
    return new Promise(async resolve => {
      if (!this.me || this.lastUpdated < Date.now()) {
        if (!this.activeRefresh) {
          // @ts-ignore
          this.activeRefresh = this.fetchFn().then(value => {
            this.setMe(value);
            this.activeRefresh = undefined;
            resolve(value);
            return value;
          });
        } else {
          // @ts-ignore
          this.activeRefresh = this.activeRefresh.then(value => {
            resolve(value);
            return value;
          });
        }
      } else {
        resolve(this.me);
      }
    });
  }

  private updateLastUpdated() {
    this.lastUpdated = Date.now() + 300;
  }

  override getMe(): Observable<Partial<UserInfoDTO>> {
    return of(anonymousUser);
  }

  override setMe(value: Partial<UserInfoDTO>) {
    this.me = value;
    this.updateLastUpdated();
    this.onCacheRefreshed?.(value);
  }
}

export class UserInfoCache2 extends AnonymousUserInfoCache {
  private me: Observable<Partial<UserInfoDTO>>;
  private gate = new Subject<void>();
  private refreshInterval: number = 500;

  private setMe$ = new Subject<Partial<UserInfoDTO>>();

  constructor(fetchFn: () => Observable<UserInfoDTO>) {
    super(fetchFn);

    let pipe1 = this.gate.pipe(
      throttleTime(this.refreshInterval, undefined, { trailing: true }),
      switchMap(el => {
        return fetchFn();
      })
    );

    this.me = merge(pipe1, this.setMe$).pipe(shareReplay(1));
    this.setMe$.next(anonymousUser);

    //dont know why, but without this "subscribe" nothing to work
    this.me.subscribe(el => {}).unsubscribe();
  }

  override getMe(): Observable<Partial<UserInfoDTO>> {
    console.log('getMe called');
    this.gate.next();
    return this.me;
  }

  override setMe(value: Partial<UserInfoDTO>) {
    this.setMe$.next(value);
  }
}
