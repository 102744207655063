import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { roles } from '../common/role-hierarchy';
import { SecurityApi } from '@api/security-api';
import { UserInfoDTO } from '@dto/user-info-dto';
import { UserInfoCache } from '@cms/services/UserInfoCache';
import { HttpClient } from '@angular/common/http';
import { UserTokenService } from '@cms/services/user-token.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
  deps: [HttpClient, SecurityApi],
})
export class SecurityService {
  private roles = roles;

  private anonymousUser = {
    id: 0,
    name: '',
    role: 'ROLE_ANONYMOUS',
  };

  private cache!: UserInfoCache;
  updateUserSubject: BehaviorSubject<Partial<UserInfoDTO>>;
  updateRoleSubject: Observable<string>;

  constructor(
    private securityApi: SecurityApi,
    private userTokenService: UserTokenService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.updateUserSubject = new BehaviorSubject<Partial<UserInfoDTO>>(this.anonymousUser);

    this.updateRoleSubject = this.updateUserSubject.pipe(
      tap(el => {}),
      map(user => user.role!!)
    );

    this.cache = new UserInfoCache(() => lastValueFrom(this.securityApi.me()));
    this.cache.bindOnCacheRefreshed(me => this.updateUserSubject.next(me));
    this.cache.getMe();
  }

  login(email: string, password: string): Observable<UserInfoDTO> {
    return this.securityApi.login(email.trim(), password).pipe(
      tap(userInfo => {
        this.cache.setMe(userInfo);
      })
    );
  }

  logout() {
    return this.securityApi.logout().pipe(
      tap(_ => {
        this.userTokenService.clearToken();
        this.cache.setMe(this.anonymousUser);
      })
    );
  }

  public inRole(role: string): Promise<boolean> {
    return new Promise(async resolve => {
      if (isPlatformServer(this.platformId)) {
        resolve(false);
        return;
      }
      let me = await this.cache.getMe();
      //@ts-ignore
      let grants = this.roles[me.role];
      resolve(grants && grants.some((r: string) => r == role));
    });
  }

  protected getReqPath(): string {
    return 'security';
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<UserInfoDTO> {
    return this.securityApi.changePassword('', oldPassword, newPassword);
  }

  public restorePasswordFirstStep(email: string): Observable<any> {
    return this.securityApi.forgetPass(email);
  }

  public restorePasswordFinalStep(key: string, newPass: string): Observable<any> {
    return this.securityApi.finishForgetPass(key, newPass);
  }
}
