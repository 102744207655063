<div class="about-container">
  <h1>Сервис ISZ - удобный сервис для быстрого поиска актуальных
    услуг на контейнерные железнодорожные перевозки.</h1>
  <p>
    Мы объединили транспортные компании в единой платформе, чтобы вы
    могли легко находить новых клиентов и предлагать свои услуги.
  </p>
  <div class="space__95"></div>
  <h2>На нашем сайте представлены следующие услуги:</h2>
  <div class="interactive-center">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="node" >
        <div class="node-icon">
          <img [src]="'/assets/images/icons/icon_truck.svg'" alt="Node Icon" />
        </div>
        <div class="node-content">
          <h3>Автодоставка контейнеров</h3>
          <p>Контейнеровоз (автомобильная доставка по схеме терминал – склад – терминал)</p>
        </div>
      </div>
      <div class="node" >
        <div class="node-icon">
          <img [src]="'/assets/images/icons/icon_out.svg'" alt="Node Icon" />
        </div>
        <div class="node-content">
          <h3>Услуги терминала</h3>
          <p>Приём и обработка железнодорожных контейнеров, оформление и раскредитование документов, буферное накопление контейнеров</p>
        </div>
      </div>
    </div>
    <div class="central-circle-wrap d-flex justify-content-center">
      <div class="central-circle">
        <img style="width: 120px;" src="/assets/images/logo.svg" alt="ICZ"/>
      </div>
    </div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="node" >
        <div class="node-icon">
          <img [src]="'/assets/images/icons/icon_in.svg'" alt="Node Icon" />
        </div>
        <div class="node-content">
          <h3>Железнодорожные перевозки</h3>
          <p>Отправка контейнеров между станциями отправления и прибытия (тариф станция – станция)</p>
        </div>
      </div>
      <div class="node" >
        <div class="node-icon">
          <img [src]="'/assets/images/icons/icon_truck_plus.svg'" alt="Node Icon" />
        </div>
        <div class="node-content">
          <h3>Полный цикл услуг</h3>
          <p>Организация перевозок по схемам: склад – станция – склад, склад – станция, станция – склад
          </p>
        </div>
      </div>
    </div>


    <img src="/assets/images/line.svg" class="line normal" />
    <img src="/assets/images/line.svg" class="line vertical-mirror" />
    <img src="/assets/images/line.svg" class="line horizontal-mirror" />
    <img src="/assets/images/line.svg" class="line both-mirror" />
  </div>

  <h2>Возможности сервиса</h2>
  <div class="about__card-container">
    <div class="about__card" *ngFor="let card of cards">
      <img [src]="card.icon" alt="Icon" class="about__card-icon" />
      <h3>{{ card.header }}</h3>
      <p>{{ card.text }}</p>
    </div>
  </div>
</div>
