<mat-card>
  <mat-card-content *ngIf="view.companyId != null; else forAnon">
    <table class="card-table__anonymous mobile">
      <tbody class="card-table__header">
      <tr>
        <td [width]="'50%'"></td>
        <td [width]="'50%'"><span class="title">актуальная цена</span><br/></td>
      </tr>
      <tr class="mobile">
        <td class="value" [width]="'50%'"></td>
        <td class="value" [width]="'50%'">
          <b>{{ view.price | currency: 'RUB' : 'symbol' : '1.0-0' : 'ru' }}</b>
        </td>
      </tr>
      <tr>
        <td [width]="'50%'"><span class="title">опубликовано</span></td>
        <td [width]="'50%'"><span class="title">размер контейнера</span></td>
      </tr>
      <tr class="mobile">
        <td class="value" [width]="'50%'">
          <div class="date-container">
            <img src="/assets/images/icons/card-icon_date.svg" alt="icon"/>&nbsp;
            {{ view.updateDate | date: 'dd.MM.yyyy' }}
          </div>
        </td>
        <td class="value" [width]="'50%'">
            <span
              class="title"
              *ngIf="view.containerType !== undefined && view.containerSize !== undefined"
            ><img src="/assets/images/icons/card-icon_container.svg" alt="icon"/>&nbsp;
              {{ContainerTypes[view.containerType]}}/{{ view.containerSize | sizeToCyrillic }}
            </span>
        </td>
      </tr>
      <tr>
        <td [width]="'100%'" colspan="2"><span class="title">маршрут</span></td>
      </tr>
      <tr class="mobile">
        <td [width]="'100%'" colspan="2" class="value direction-cell">
          <div class="direction-content">
            <img src="/assets/images/flags/rf.png" alt="flag" class="flag"/>
            <span class="directions">{{ view.cityFrom }} - <br>
                <img src="/assets/images/flags/rf.png" alt="flag" class="flag"/>
              {{ view.cityTo }}</span>
          </div>
        </td>
      </tr>

      <tr>
        <td [width]="'100%'" colspan="2">
          <img src="/assets/images/icons/card-icon_company.svg" alt="icon"/>&nbsp;Компания
        </td>
      </tr>
      <tr class="mobile">
        <td [width]="'100%'" colspan="2" class="value">
          {{ view.companyName }}
        </td>
      </tr>
      <tr>
        <td [width]="'100%'" colspan="2">
          <img src="/assets/images/icons/card-icon_contact.svg" alt="icon"/>&nbsp;Контакты
        </td>
      </tr>
      <tr class="mobile">
        <td [width]="'100%'" colspan="2" class="value">
          <app-contacts-cell [contacts]="view.mappedContacts"></app-contacts-cell>
        </td>
      </tr>
      <tr *ngIf="view.comment">
        <td [width]="'100%'" colspan="2">
          <img src="/assets/images/icons/card-icon_comment.svg" alt="icon"/>&nbsp;Комментарий
        </td>
      </tr>
      <tr class="mobile" *ngIf="view.comment">
        <td [width]="'100%'" colspan="2" class="value">
          <div
            class="comment-container"
            [class.expanded]="view.isFullCommentShown"
            [class.has-gradient]="view.comment && view.comment.length > 100"
          >
            <div class="comment">
              {{ view.truncatedComment }}
            </div>
          </div>
          <button
            *ngIf="view.comment && view.comment.length > 100"
            mat-button
            color="primary"
            class="toggle-button"
            (click)="view.toggleComment()"
          >
            {{ view.isFullCommentShown ? 'Скрыть' : 'Читать далее' }}
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </mat-card-content>
  <ng-template #forAnon>
    <mat-card-content>
      <table class="card-table__anonymous mobile">
        <tbody class="card-table__header">
        <tr>
          <td [width]="'50%'"><span class="title">опубликовано</span></td>
          <td [width]="'50%'"><span class="title">размер контейнера</span></td>
        </tr>
        <tr class="mobile">
          <td class="value" [width]="'50%'">
            <div class="date-container">
              <img src="/assets/images/icons/card-icon_date.svg" alt="icon"/>&nbsp;{{
              view.updateDate | date: 'dd.MM.yyyy'
              }}
            </div>
          </td>
          <td class="value" [width]="'50%'">
            <span
              class="title"
              *ngIf="view.containerType !== undefined && view.containerSize !== undefined"
            ><img src="/assets/images/icons/card-icon_container.svg" alt="icon"/>&nbsp;{{
              ContainerTypes[view.containerType]
              }}/{{ view.containerSize | sizeToCyrillic }}</span
            >
          </td>
        </tr>
        <tr>
          <td [width]="'100%'" colspan="2"><span class="title">маршрут</span></td>
        </tr>
        <tr class="mobile">
          <td [width]="'100%'" colspan="2" class="value direction-cell">
            <div class="direction-content">
              <img src="/assets/images/flags/rf.png" alt="flag" class="flag"/>
              <span class="directions">{{ view.cityFrom }} -<br>
                <img src="/assets/images/flags/rf.png" alt="flag" class="flag"/>
                {{ view.cityTo }}</span>
            </div>
          </td>
        </tr>
        <tr class="mobile">
          <td [width]="'100%'" class="value center-content" colspan="2">
            <button class="subscribe-button" (click)="openLoginDialog()">Узнать контакты и ставку
            </button
            >
            <br/>
            <p class="sub-subscribe-button">Информация доступна по подписке</p>
          </td>
        </tr>
        </tbody>
      </table>
    </mat-card-content>
  </ng-template>
</mat-card>
