<div class="form-container" *ngIf="key">
  <form [formGroup]="form">
    <div class="form-row">
      <div class="input-with-icon">
        <mat-icon matPrefix class="input-icon">lock_outline</mat-icon>
        <mat-form-field appearance="fill" class="with-icon">
          <mat-label>Новый пароль</mat-label>
          <input
            [formControl]="form.password"
            [errorStateMatcher]="passErrorStateMatcher"
            matInput
            type="password"
          />
          <mat-error *ngIf="form.password.error('required')">Обязательное поле</mat-error>
          <mat-error *ngIf="form.password.hasError('minlength')"
            >Пароль должен быть не менее 7 символов
          </mat-error>
        </mat-form-field>
      </div>

      <div class="input-with-icon">
        <mat-icon matPrefix class="input-icon">lock_outline</mat-icon>
        <mat-form-field appearance="fill" class="with-icon">
          <mat-label>Подтверждение пароля</mat-label>
          <input
            [formControl]="form.password2"
            [errorStateMatcher]="passErrorStateMatcher"
            matInput
            type="password"
          />
          <mat-error *ngIf="form.password2.error('required')">Обязательное поле</mat-error>
          <mat-error *ngIf="form.password2.hasError('minlength')"
            >Пароль должен быть не менее 7 символов
          </mat-error>
          <mat-error *ngIf="form.error('passNotEquals')">Пароли должны совпадать</mat-error>
          <mat-error *ngIf="form.password2.error('wrongOldPassword')"
            >Ссылка на смену пароля не актуальна, пройдите процедуру восстановления заново
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <button mat-raised-button color="primary" (click)="changePassword()">Изменить</button>
    </div>
  </form>
</div>
