<div class="center-content">
  <img
    srcset="
      /assets/images/404-400w.avif   400w,
      /assets/images/404-600w.avif   600w,
      /assets/images/404-800w.avif   800w,
      /assets/images/404-1000w.avif 1000w,
      /assets/images/404-1200w.avif 1200w,
      /assets/images/404-1600w.avif 1600w,
      /assets/images/404-2000w.avif 2000w
    "
    sizes="(max-width: 400px) 400px, (max-width: 600px) 600px, (max-width: 800px) 800px, (max-width: 1000px) 1000px, (max-width: 1200px) 1200px, (max-width: 1600px) 1600px, (min-width: 1601px) 2000px"
    src="/assets/images/404.png"
    alt="404"
  />
  <h1>404</h1>
  <p>Страница не найдена</p>
</div>
