import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyContacts } from '@dto/company-contacts';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-contacts-cell',
  standalone: true,
  imports: [CommonModule, NgxMaskPipe],
  providers: [provideNgxMask()],
  templateUrl: './contacts-cell.component.html',
  styleUrls: ['./contacts-cell.component.css'],
})
export class ContactsCellComponent {
  @Input({ required: true })
  contacts!: Array<CompanyContacts>;
}
