import {RegistrationDTO} from "./registration-dto"
// @ts-ignore
import {parseDate} from "./base-entity"


export class ChangePasswordDTO extends RegistrationDTO  {
     newPassword?:string


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem



            RegistrationDTO.prototype.prepare.apply(this)



        return this
    }

}

