import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  standalone: true,
  imports: [MatCardModule, MatIconModule, NgFor],
})
export class FaqComponent {
  faqs = [
    { id: 'chto-takoe-isz', question: 'Что такое ISZ?', icon: 'info' },
    { id: 'kak-zaregistrirovatsya', question: 'Как зарегистрироваться', icon: 'person' },
    { id: 'kak-zapolnit-dannye-kompanii', question: 'Как заполнить данные компании', icon: 'business' },
    { id: 'kak-sozdat-uslugu', question: 'Как создать услугу', icon: 'build' },
    { id: 'kak-sozdat-sotrudnika', question: 'Как создать сотрудника', icon: 'person_add' },
    { id: 'kak-naznachit-sotrudnika', question: 'Как назначить сотрудника', icon: 'group_add' },
    {
      id: 'kak-otvyazat-sotrudnika-ot-uslugi',
      question: 'Как отвязать сотрудника от услуги',
      icon: 'person_off',
    },
    { id: 'kak-otkryt-kontakty-kompanii', question: 'Как открыть контакты компании', icon: 'contact_page' },
    { id: 'kak-aktivirovat-podpisku', question: 'Как активировать подписку', icon: 'subscriptions' },
    { id: 'kak-oplatit-schet', question: 'Как оплатить счет', icon: 'payment' },
    {
      id: 'ne-udaetsya-vojti-v-lichnyj-kabinet',
      question: 'Не удается войти в Личный кабинет',
      icon: 'lock',
    },
    {
      id: 'oplachen-schet-no-podpisku-ne-aktivirovali',
      question: 'Оплачен счёт, но подписка не активирована',
      icon: 'visibility_off',
    },
  ];

  constructor(private router: Router) {}

  navigateToFaq(id: string): void {
    this.router.navigate([`/faq/${id}`]);
  }
}
