import { ServiceType } from '@dto/service-type';
import { ContainerSize } from '@dto/container-size';
import { ContainerType } from '@dto/container-type';
import { CriteriaDTO } from '@dto/criteria-dto';
import { SearchCondition } from '@dto/search-condition';
import { Params } from '@angular/router';
import { AutoOutput } from '@gen/common/Autocomplete';

export class SearchFilters {
  destination?: AutoOutput;

  departure?: AutoOutput;
  serviceType?: ServiceType;

  containerSize: ContainerSize[] = [];

  containerType: ContainerType[] = [];

  toCriteriaFilters(): Array<CriteriaDTO> {
    let filters = new Array<CriteriaDTO>();

    let dest = this.destination;
    if (dest) {
      filters.push({
        field: 'cityTo',
        condition: SearchCondition.EQ,
        val1: dest.id,
      });
    }

    let dep = this.departure;
    if (dep) {
      filters.push({
        field: 'cityFrom',
        condition: SearchCondition.EQ,
        val1: dep.id,
      });
    }

    let type = this.serviceType;
    if (type !== undefined) {
      filters.push({
        field: 'serviceType',
        condition: SearchCondition.EQ,
        val1: ServiceType[type],
      });
    }

    this.containerType.forEach(el => {
      filters.push({
        field: 'containerType',
        condition: SearchCondition.EQ,
        val1: ContainerType[el],
      });
    });

    this.containerSize.forEach(el => {
      filters.push({
        field: 'containerSize',
        condition: SearchCondition.EQ,
        val1: ContainerSize[el],
      });
    });

    return filters;
  }

  toQueryParams(): Params {
    let result: Params = {};

    let dest = this.destination;
    if (dest) {
      result['destination'] = dest.id;
    }

    let dep = this.departure;
    if (dep) {
      result['departure'] = dep.id;
    }

    let serviceType = this.serviceType;
    if (serviceType !== undefined) {
      result['serviceType'] = ServiceType[serviceType];
    }

    result['containerType'] = [];
    this.containerType.forEach(el => {
      result['containerType'].push(ContainerType[el]);
    });

    result['containerSize'] = [];
    this.containerSize.forEach(el => {
      result['containerSize'].push(ContainerSize[el]);
    });

    return result;
  }

  fromQueryParams(params: Params) {
    this.clean();

    if (params['destination']) {
      this.destination = {
        value: undefined,
        id: params['destination'],
      };
    }

    if (params['departure']) {
      this.departure = {
        value: undefined,
        id: params['departure'],
      };
    }

    if (params['serviceType']) {
      this.serviceType = ServiceType[params['serviceType'] as keyof typeof ServiceType];
    }

    if (params['containerType']) {
      if (Array.isArray(params['containerType'])) {
        this.containerType = params['containerType'].map(
          el => ContainerType[el as keyof typeof ContainerType]
        );
      } else {
        this.containerType = [ContainerType[params['containerType'] as keyof typeof ContainerType]];
      }
    }

    if (params['containerSize']) {
      if (Array.isArray(params['containerSize'])) {
        this.containerSize = params['containerSize'].map(
          el => ContainerSize[el as keyof typeof ContainerSize]
        );
      } else {
        this.containerSize = [ContainerSize[params['containerSize'] as keyof typeof ContainerSize]];
      }
    }
  }

  private clean() {
    this.destination = undefined;
    this.departure = undefined;
    this.serviceType = undefined;
    this.containerSize = [];
    this.containerType = [];
  }
}
