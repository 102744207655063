import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { TariffsApi } from '@api/tariffs.service';
import { SearchCondition } from '@dto/search-condition';
import { LkSelectTariffDTO } from '@dto/lk-select-tariff-dto';
import { LkSelectTariffDTOApi } from '@api/lk-select-tariff-dto.service';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { CompanyEmptyDialogDialogComponent } from '@common/company-info-empty-dialog/app-company-info-empty-dialog.component';
import { RouterLink } from '@angular/router';
import { MonthEndingPipe } from '@cms/pipes/month-ending.pipe';

interface Tiers {
  id: number;
  name: string;
  header: string;
  features: Array<String>;
  price: string;
}

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, RouterLink],
  providers: [MonthEndingPipe],
})
export class SubscriptionComponent implements OnInit {
  @Input() useStyledContainer: boolean = true;
  tiers = new Array<Tiers>();

  constructor(
    private tariffsApi: TariffsApi,
    private lkSelectTariffApi: LkSelectTariffDTOApi,
    private dialog: MatDialog,
    private monthEndingPipe: MonthEndingPipe
  ) {}

  ngOnInit(): void {
    this.tariffsApi
      .getAll({
        filters: [
          {
            field: 'archived',
            condition: SearchCondition.EQ,
            val1: false,
          },
        ],
      })
      .subscribe(response => {
        this.tiers = response.content.map(el => {
          return {
            id: el.id,
            name: el.name!!,
            header: this.monthEndingPipe.transform(Math.floor(el.duration!! / 30)),
            features: el.features!!.split(';'),
            price: el.price + '₽ /мес.',
          };
        });
      });
  }

  onSelect(id: number) {
    let dto = new LkSelectTariffDTO();
    dto.id = id;
    this.lkSelectTariffApi.save(dto).subscribe({
      next: el => {
        this.dialog.closeAll();
        const dialogRef = this.dialog.open(SuccessDialogComponent);
        dialogRef.afterClosed().subscribe(() => {});
      },
      error: error => {
        if (error.code == 'CompanyInfoEmptyException') {
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(CompanyEmptyDialogDialogComponent);
          dialogRef.afterClosed().subscribe(() => {});
        }
      },
    });
  }
}
