<mat-button-toggle-group
  *ngIf="!isMobile; else mobileView"
  class="custom-toggle-group"
  name="transportType"
  aria-label="Transport Type"
  [value]="serviceForTabs"
  (valueChange)="onValueChange($event)"
>
  <mat-button-toggle value="{{ 'ALL' }}">
    <mat-icon svgIcon="all" aria-hidden="false" aria-label="all"></mat-icon>
    Все
  </mat-button-toggle>
  <mat-button-toggle value="{{ ServiceTypes[ServiceTypes.AUTO] }}">
    <mat-icon svgIcon="truck" aria-hidden="false" aria-label="truck"></mat-icon>
    Автотранспорт
  </mat-button-toggle>
  <mat-button-toggle value="{{ ServiceTypes[ServiceTypes.TERMINAL_RX] }}">
    <mat-icon svgIcon="terminal_in" aria-hidden="false" aria-label="terminal_in"></mat-icon>
    Терминал на прием
  </mat-button-toggle>
  <mat-button-toggle value="{{ ServiceTypes[ServiceTypes.TERMINAL_TX] }}">
    <mat-icon svgIcon="terminal_out" aria-hidden="false" aria-label="terminal_out"></mat-icon>
    Терминал на отправку
  </mat-button-toggle>
  <mat-button-toggle value="{{ ServiceTypes[ServiceTypes.AUTO_TERMINAL] }}">
    <mat-icon svgIcon="truck_plus" aria-hidden="false" aria-label="truck_plus"></mat-icon>
    Авто+Терминал
  </mat-button-toggle>
  <mat-button-toggle value="{{ ServiceTypes[ServiceTypes.CONTAINER_RENT] }}">
    <mat-icon svgIcon="container_rent" aria-hidden="false" aria-label="truck_plus"></mat-icon>
    Аренда контейнеров
  </mat-button-toggle>
</mat-button-toggle-group>

<ng-template #mobileView>
  <div class="mobile-grid">
    <div
      class="button-container"
      [class.active]="selectedType === undefined"
      (click)="onValueChange('ALL')"
    >
      <mat-icon class="button-icon" svgIcon="all"></mat-icon>
      Все
    </div>
    <div
      class="button-container"
      [class.active]="selectedType === ServiceTypes.AUTO"
      (click)="onValueChange(ServiceTypes[ServiceTypes.AUTO])"
    >
      <mat-icon class="button-icon" svgIcon="truck"></mat-icon>
      Автотранспорт
    </div>
    <div
      class="button-container"
      [class.active]="selectedType === ServiceTypes.TERMINAL_RX"
      (click)="onValueChange(ServiceTypes[ServiceTypes.TERMINAL_RX])"
    >
      <mat-icon class="button-icon" svgIcon="terminal_in"></mat-icon>
      Терминал на прием
    </div>
    <div
      class="button-container"
      [class.active]="selectedType === ServiceTypes.TERMINAL_TX"
      (click)="onValueChange(ServiceTypes[ServiceTypes.TERMINAL_TX])"
    >
      <mat-icon class="button-icon" svgIcon="terminal_out"></mat-icon>
      Терминал на отправку
    </div>
    <div
      class="button-container"
      [class.active]="selectedType === ServiceTypes.AUTO_TERMINAL"
      (click)="onValueChange(ServiceTypes[ServiceTypes.AUTO_TERMINAL])"
    >
      <mat-icon class="button-icon" svgIcon="truck_plus"></mat-icon>
      Авто+Терминал
    </div>
    <div
      class="button-container"
      [class.active]="selectedType === ServiceTypes.CONTAINER_RENT"
      (click)="onValueChange(ServiceTypes[ServiceTypes.CONTAINER_RENT])"
    >
      <mat-icon class="button-icon" svgIcon="container_rent"></mat-icon>
      Аренда контейнеров
    </div>
  </div>
</ng-template>
