<div class="header__wrapper">
  <a routerLink="/" class="logo">
    <img src="assets/images/logo.svg" alt="Logo"/>
  </a>

  <span *ngIf="!isMobile" class="toolbar-spacer"></span>
  <a *ngIf="!isMobile" class="nav-link" routerLink="/">Главная</a>
  <a *ngIf="!isMobile" class="nav-link" routerLink="/about">О нас</a>
  <a *ngIf="!isMobile" class="nav-link" routerLink="/faq">FAQ</a>
  <a *ngIf="!isMobile" class="nav-link" routerLink="/subscription">Подписка</a>
  <a *ngIf="!isMobile" class="nav-link" routerLink="/contacts">Контакты</a>


  <app-profile-button></app-profile-button>

  <!-- Mobile Burger Menu -->
  <button *ngIf="isMobile" mat-icon-button [matMenuTriggerFor]="menu" class="burger-menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/">Главная</button>
    <button mat-menu-item routerLink="/about">О нас</button>
    <button mat-menu-item routerLink="/faq">FAQ</button>
    <button mat-menu-item routerLink="/subscription">Подписка</button>
    <button mat-menu-item routerLink="/contacts">Контакты</button>
  </mat-menu>
</div>
