<div class="contacts-container" *ngIf="contacts.length; else noContacts">
  <ng-container *ngFor="let c of contacts">
    <div class="contact-name">{{ c.contactName }}</div>
    <div class="contact-phone">{{ c.contactPhone!! | mask: '(000) 000-00-00' : { prefix: '+7' } }}</div>
    <div class="contact-email">{{ c.contactEmail }}</div>
  </ng-container>
</div>
<ng-template #noContacts>
  <div class="trial-version__holder">
    <img src="/assets/images/icons/icon_trial_contact.svg" alt="lock icon" />
  </div>
</ng-template>
