// @ts-ignore
import {parseDate} from "./base-entity"


export class LkSelectTariffDTO   {
     id?:number


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem






        return this
    }

}

