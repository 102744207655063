import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthEnding',
  standalone: true,
})
export class MonthEndingPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    let lastDigit = value % 10;
    let lastTwoDigits = value % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return value + ' месяцев';
    } else if (lastDigit == 1) {
      return value + ' месяц';
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return value + ' месяца';
    } else {
      return value + ' месяцев';
    }
  }
}
