import { Component } from '@angular/core';
import { NgFor, NgStyle } from '@angular/common';

interface Node {
  icon: string;
  title: string;
  description: string;
  position: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
}

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  standalone: true,
  imports: [NgFor, NgStyle],
})
export class AboutComponent {
  cards = [
    {
      icon: '/assets/images/icons/icon_01_one.svg',
      header: 'Размещение своих ставок на перевозку',
      text: 'Возможность оперативно предлагать свои тарифы на перевозки, находя новых клиентов и партнёров',
    },
    {
      icon: '/assets/images/icons/icon_04_web.svg',
      header: 'Публикация запросов на выполнение перевозки',
      text: 'Размещение запросов для поиска исполнителей, что позволяет быстро найти оптимальные условия перевозки',
    },
    {
      icon: '/assets/images/icons/icon_03_direction.svg',
      header: 'Просмотр актуальных услуг и цен от конкретных компаний',
      text: 'Доступ к актуальным предложениям, что позволяет оперативно принимать решения',
    },
    {
      icon: '/assets/images/icons/icon_02_help.svg',
      header: 'Персональный менеджер',
      text: 'Наш специалист поможет вам разместить услуги, проконсультирует по функционалу платформы и согласует необходимые условия',
    },
    {
      icon: '/assets/images/icons/icon_05_actual.svg',
      header: 'Ответ на запрос в течение 24 часов',
      text: 'Мы гарантируем оперативную обратную связь по любому вашему запросу или заявке, помогая экономить время и повышать эффективность бизнеса',
    },
  ];

  nodes: Node[] = [
    {
      icon: '/assets/images/icons/icon_truck.svg',
      title: 'Автодоставка контейнеров',
      description: 'Доставка контейнеров до выбранного терминала посредством автомобиля',
      position: { top: '0', left: '0' },
    },
    {
      icon: '/assets/images/icons/icon_out.svg',
      title: 'Услуги терминала',
      description: 'Отправление транспортированных контейнеров в пункт назначения',
      position: { top: '0', right: '0' },
    },
    {
      icon: '/assets/images/icons/icon_in.svg',
      title: 'Ж/Д перевозки',
      description: 'Погрузка, отгрузка и буферное накопление контейнеров  в пунктах приема',
      position: { bottom: '0', left: '0' },
    },
    {
      icon: '/assets/images/icons/icon_truck_plus.svg',
      title: 'Полный цикл услуг',
      description:
        'Полный пакет услуг с транспортировкой до выбранных пунктов назначения и отправления с последующей погрузкой и отгрузкой',
      position: { bottom: '0', right: '0' },
    },
  ];
}
