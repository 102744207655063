<app-routs-search-form [searchFilter]="filters" (onSearchFilter)="doSearch($event)"></app-routs-search-form>

<!-- Table -->
<div class="table-container">
  <div class="striped-table-container">
    <!-- <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="updateDate">
        <mat-header-cell *matHeaderCellDef class="narrow-column">
          <img src="/assets/images/icons/icon_table__date.svg" alt="Date icon" class="header-icon" /> Дата
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="narrow-column">
          {{ element.updateDate | date: 'dd.MM.yyyy' }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="route">
        <mat-header-cell *matHeaderCellDef class="wide-column">
          <img src="/assets/images/icons/icon_table__map.svg" alt="Route icon" class="header-icon" /> Маршрут
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="route-cell wide-column">
          <div class="route-info">
            {{ element.cityFrom }}<br />
            {{ element.cityTo }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <mat-header-cell *matHeaderCellDef class="wide-column">
          <img src="/assets/images/icons/icon_table__company.svg" alt="Company icon" class="header-icon" />
          Компания
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wide-column">
          <ng-container *ngIf="element.companyName; else trialVersion">
            {{ element.companyName }}
          </ng-container>
          <ng-template #trialVersion>
            <span class="trial-version">
              <img src="/assets/images/icons/icon_lock.svg" alt="lock icon" /> Пробная версия
            </span>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef class="wide-column">
          <img src="/assets/images/icons/icon_table__comment.svg" alt="Comment icon" class="header-icon" />
          Комментарий
        </mat-header-cell>

        <mat-cell *matCellDef="let element" class="wide-column">
          <ng-container *ngIf="element.companyName; else trialVersion">
            {{ element.comment }}
          </ng-container>
          <ng-template #trialVersion>
            <span class="trial-version">
              <img src="/assets/images/icons/icon_lock.svg" alt="lock icon" /> Пробная версия
            </span>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="contacts">
        <mat-header-cell *matHeaderCellDef class="wide-column">
          <img src="/assets/images/icons/icon_table__contact.svg" alt="Contact icon" class="header-icon" />
          Контакт
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wide-column">
          <app-contacts-cell [contacts]="element.contacts"></app-contacts-cell>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef class="narrow-column">
          <img src="/assets/images/icons/icon_table__stake.svg" alt="Stake icon" class="header-icon" /> Ставка
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="narrow-column">
          <ng-container *ngIf="element.price; else trialVersion">
            {{ element.price | currency: 'RUB' : 'symbol' : '1.0-0' : 'ru' }}
          </ng-container>
          <ng-template #trialVersion>
            <div class="trial-version__holder">
              <img src="/assets/images/icons/icon_trial_amount.svg" alt="lock icon" />
            </div>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="containerType">
        <mat-header-cell *matHeaderCellDef class="narrow-column">
          <img
            src="/assets/images/icons/icon_table__type_size.svg"
            alt="Type/Size icon"
            class="header-icon"
          />
          Тип/Размер
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="narrow-column">
          {{ ContainerTypes[element.containerType] }}/{{ element.containerSize | sizeToCyrillic }}
        </mat-cell>
      </ng-container>

      <mat-header-row class="table__header" *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <app-empty-search *ngIf="dataSource.data.length === 0"></app-empty-search> -->

    <!-- Cards -->
    <div class="cards-container">
      <ng-container *ngIf="services.length; else noServices">
        <ng-container *ngFor="let service of services">
          <app-routs-card [service]="service"></app-routs-card>
        </ng-container>
      </ng-container>
    </div>

    <ng-template #noServices>
      <app-empty-search></app-empty-search>
    </ng-template>

    <mat-paginator
      class="mt-2"
      [length]="length"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions"
      [hidePageSize]="hidePageSize"
      (page)="onPaginatorEvent($event)"
      aria-label="Выберите страницу"
      *ngIf="dataSource.data.length != 0"
    >
    </mat-paginator>
  </div>
</div>
