import {
  Component,
  computed,
  effect,
  EventEmitter,
  HostListener,
  Input,
  Output,
  signal,
  untracked,
  ViewChild,
} from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ContainerSize } from '@dto/container-size';
import { ContainerType } from '@dto/container-type';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { SingleSelectDirective } from '@cms/directives/single-select.directive';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { SelectedContainerFilter } from './selected-container-filter';
import { UnsubscribeOnDestroyAdapter } from '@gen/common/UnsubscribeOnDestroyAdapter';
import { ExternalStateMatcher } from '@cms/common/external-state.matcher';

@Component({
  selector: 'app-container-type-select',
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    NgIf,
    MatButtonModule,
    SingleSelectDirective,
    PortalModule,
    MatRadioModule,
    ReactiveFormsModule,
  ],
  templateUrl: './container-type-select.component.html',
  styleUrls: ['./container-type-select.component.css'],
})
export class ContainerTypeSelectComponent extends UnsubscribeOnDestroyAdapter {
  @Input()
  errorState = new Array<boolean>(false, false);

  @Output()
  onChange = new EventEmitter<SelectedContainerFilter>(true);

  @Input({ required: true })
  set selectedOptions(value: SelectedContainerFilter | undefined) {
    if (value) {
      this.selectedSize.set(ContainerSize[value.size[0]]);
      this.selectedType.set(ContainerType[value.type[0]]);
    }
  }

  @ViewChild('parent')
  parent!: MatFormField;

  @ViewChild(CdkPortal) public contentTemplate!: CdkPortal;
  private overlayRef!: OverlayRef;

  @HostListener('window:resize')
  public onWinResize(): void {
    this.syncWidth();
  }

  stateMatcher = new ExternalStateMatcher(() => this.errorState[0] || this.errorState[1]);

  formGroup = new FormGroup({
    formControl: new UntypedFormControl(),
  });

  containerSizes = [
    { value: ContainerSize[ContainerSize.FT20], viewValue: '20ft' },
    { value: ContainerSize[ContainerSize.FT40], viewValue: '40ft' },
  ];
  containerTypes = [
    { value: ContainerType[ContainerType.COC], viewValue: 'COC' },
    { value: ContainerType[ContainerType.SOC], viewValue: 'SOC' },
  ];
  selectedSize = signal<string | undefined>(undefined);
  selectedType = signal<string | undefined>(undefined);

  private triggerSignal = signal<boolean>(false, { equal: (a, b) => false });

  constructor(private overlay: Overlay) {
    super();
    effect(_ => {
      if (!this.triggerSignal()) {
        return;
      }
      let result = new SelectedContainerFilter();
      untracked(() => {
        if (this.selectedSize()) {
          result.size = [ContainerSize[this.selectedSize() as keyof typeof ContainerSize]];
        }
      });
      untracked(() => {
        if (this.selectedType()) {
          result.type = [ContainerType[this.selectedType() as keyof typeof ContainerType]];
        }
      });
      this.onChange.emit(result);
    });
    effect(_ => {
      this.formGroup.controls['formControl'].patchValue(this.computeSelectedText());
    });
  }

  computeSelected = computed(() => {
    return [this.selectedSize() ?? undefined, this.selectedType() ?? undefined];
  });

  computeSelectedText = computed(() => {
    let result = [
      this.containerSizes.find(el => el.value == this.selectedSize()),
      this.containerTypes.find(el => el.value == this.selectedType()),
    ]
      .filter(el => !!el)
      .map(el => el!.viewValue);
    return result.length ? result.reduce((acc, v) => acc + ', ' + v) : '';
  });

  clearSelections(event: MouseEvent) {
    event.stopPropagation();
    this.selectedSize.set(undefined);
    this.selectedType.set(undefined);
    this.triggerSignal.set(true);
  }

  typeChanges(event: MatRadioChange) {
    this.selectedType.set(event.value);
    this.triggerSignal.set(true);
  }

  sizeChanges(event: MatRadioChange) {
    this.selectedSize.set(event.value);
    this.triggerSignal.set(true);
  }

  showOptions() {
    if (!this.overlay || !this.parent) return;
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create(this.getOverlayConfig());
      this.subs.sink = this.overlayRef.backdropClick().subscribe(() => this.hideOptions());
    }
    if (!this.overlayRef.hasAttached()) {
      this.overlayRef.attach(this.contentTemplate);
    }
    this.syncWidth();
  }

  hideOptions() {
    this.overlayRef.detach();
  }

  private syncWidth(): void {
    if (!this.overlayRef) {
      return;
    }
    //const refRectWidth = this.parent._elementRef.nativeElement.getBoundingClientRect().width + 150;
    this.overlayRef.updateSize({ width: 283, height: 167 });
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.parent._elementRef.nativeElement)
      .withPush(true)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          offsetY: -18,
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetY: -4,
        },
      ]);

    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    return new OverlayConfig({
      positionStrategy: positionStrategy,
      scrollStrategy: scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }
}
