import { UserInfoDTO } from '@dto/user-info-dto';

export class UserInfoCache {
  private me!: Partial<UserInfoDTO>;

  private onCacheRefreshed?: (me: Partial<UserInfoDTO>) => void;
  private lastUpdated = Date.now();

  constructor(private fetchFn: () => Promise<UserInfoDTO>) {}

  private activeRefresh?: Promise<Partial<UserInfoDTO>>;

  private refreshCache(): Promise<Partial<UserInfoDTO>> {
    return new Promise(async resolve => {
      if (!this.me || this.lastUpdated < Date.now()) {
        if (!this.activeRefresh) {
          // @ts-ignore
          this.activeRefresh = this.fetchFn().then(value => {
            this.setMe(value);
            this.activeRefresh = undefined;
            resolve(value);
            return value;
          });
        } else {
          // @ts-ignore
          this.activeRefresh = this.activeRefresh.then(value => {
            resolve(value);
            return value;
          });
        }
      } else {
        resolve(this.me);
      }
    });
  }

  bindOnCacheRefreshed(cb: (me: Partial<UserInfoDTO>) => void) {
    this.onCacheRefreshed = cb;
  }

  private updateLastUpdated() {
    this.lastUpdated = Date.now() + 300;
  }

  getMe(): Promise<Partial<UserInfoDTO>> {
    return this.refreshCache();
  }

  setMe(value: Partial<UserInfoDTO>) {
    this.me = value;
    this.updateLastUpdated();
    this.onCacheRefreshed?.(value);
  }
}
