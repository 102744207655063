// @ts-ignore
import {parseDate} from "./base-entity"


export class UserInfoDTO   {
     id?:number
     name?:string
     role?:string


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem






        return this
    }

}

