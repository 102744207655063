import {ForgetPassDTO} from "./forget-pass-dto"
// @ts-ignore
import {parseDate} from "./base-entity"


export class LoginDTO extends ForgetPassDTO  {
     password?:string


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem



            ForgetPassDTO.prototype.prepare.apply(this)



        return this
    }

}

