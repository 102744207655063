import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AboutComponent } from './pages/about/about.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FaqDetailComponent } from './pages/faq-detail/faq-detail.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { LkComponent } from './lk/lk.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RoleGuard } from '@cms/role-guard.service';
import { RestorePasswordComponent } from './pages/restore-password/restore-password.component';
import TermsComponent from './pages/terms/terms.component';
import PrivateInfoComponent from './pages/private-info/private-info.component';
import PrivacyPolicyComponent from './pages/privacy-policy/privacy-policy.component';
import ServicesAgreementComponent from './pages/services-agreement/services-agreement.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'about', component: AboutComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'faq/:id', component: FaqDetailComponent },
  { path: 'subscription', component: SubscriptionComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'login', redirectTo: '/', pathMatch: 'full' },
  { path: 'terms', loadComponent: () => import('./pages/terms/terms.component') },
  { path: 'private-info', loadComponent: () => import('./pages/private-info/private-info.component') },
  { path: 'privacy-policy', loadComponent: () => import('./pages/privacy-policy/privacy-policy.component') },
  {
    path: 'services-agreement',
    loadComponent: () => import('./pages/services-agreement/services-agreement.component'),
  },
  { path: 'restore-password/:key', component: RestorePasswordComponent },
  {
    path: 'lk',
    component: LkComponent,
    canActivate: [() => RoleGuard('ROLE_USER')],
    canActivateChild: [() => RoleGuard('ROLE_USER')],
    children: [
      {
        path: '',
        redirectTo: 'company-data',
        pathMatch: 'full',
      },
      {
        path: 'company-data',
        loadComponent: () =>
          import('./lk/company-info/company-info.component').then(mod => mod.CompanyInfoComponent),
      },
      {
        path: 'employee-contacts',
        loadComponent: () =>
          import('./lk/employee-contacts/employee-contacts.component').then(
            mod => mod.EmployeeContactsComponent
          ),
      },
      {
        path: 'employee-contacts/:id',
        loadComponent: () =>
          import('./lk/employee-contacts/add-employee-contact/add-employee-contact.component').then(
            mod => mod.AddEmployeeContactComponent
          ),
      },
      {
        path: 'your-subscription',
        loadComponent: () =>
          import('./lk/your-subscription/your-subscription.component').then(
            mod => mod.YourSubscriptionComponent
          ),
      },
      {
        path: 'change-password',
        loadComponent: () =>
          import('./lk/change-password/change-password.component').then(mod => mod.ChangePasswordComponent),
      },
      {
        path: 'your-services',
        loadComponent: () =>
          import('./lk/your-services/your-services.component').then(mod => mod.YourServicesComponent),
        children: [
          {
            path: 'new',
            loadComponent: () =>
              import('./lk/your-services/edit-service/edit-service.component').then(
                mod => mod.EditServiceComponent
              ),
          },
          {
            path: '',
            loadComponent: () =>
              import('./lk/your-services/your-service-list/your-service-list.component').then(
                mod => mod.YourServiceListComponent
              ),
          },
          {
            path: ':serviceType',
            loadComponent: () =>
              import('./lk/your-services/your-service-list/your-service-list.component').then(
                mod => mod.YourServiceListComponent
              ),
          },
          {
            path: 'edit/:id',
            loadComponent: () =>
              import('./lk/your-services/edit-service/edit-service.component').then(
                mod => mod.EditServiceComponent
              ),
          },
          {
            path: 'clone/:id',
            data: { clone: true },
            loadComponent: () =>
              import('./lk/your-services/edit-service/edit-service.component').then(
                mod => mod.EditServiceComponent
              ),
          },
        ],
      },

      {
        path: 'issued-invoices',
        loadComponent: () =>
          import('./lk/issued-invoices/issued-invoices.component').then(mod => mod.IssuedInvoicesComponent),
      },
      {
        path: 'admin',
        canActivate: [() => RoleGuard('ROLE_ADMIN')],
        loadComponent: () => import('./lk/admin/admin.component'),
      },
    ],
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
