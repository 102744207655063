// @ts-ignore
import {parseDate} from "./base-entity"


export class ForgetPassDTO   {
     email?:string


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem






        return this
    }

}

