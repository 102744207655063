<div class="h1__container">
  <h1>Все выгодные предложения в одном месте</h1>
  <p>
    Будьте в рынке: создавайте и ищите контейнерные перевозки<br />
    при меньших затратах.
  </p>
</div>
<div class="hero_image">
  <img ngSrc="/assets/images/hero.png" alt="Builder" width="300" height="300" />
</div>
<app-routs-table></app-routs-table>
