<div>
  <app-service-type-tabs
    [selectedType]="searchFilter.serviceType"
    (onSelectedType)="selectServiceType($event)"
  ></app-service-type-tabs>

  <app-service-routs-form
    [searchFilter]="searchFilter"
    (onFilter)="applyFilter()"
  ></app-service-routs-form>
</div>
