<footer class="footer">
  <div class="footer-content">
    <div class="footer-row">
      <a routerLink="/" class="footer-logo">
        <img src="assets/images/logo.svg" alt="Logo"/>
      </a>
    </div>
    <div class="footer-row">
      <p class="footer-text">
        Воспроизводство, копирование, воспроизведение, тиражирование и иное<br />
        использование информации с сайта возможно только с письменного разрешения.
      </p>
    </div>
    <hr />
    <div class="footer-row">
      <div class="footer-column align-left">Все права защищены</div>
      <div class="footer-column align-center">© 2025 ООО "ИСЗ"</div>
      <a routerLink="/private-info" class="footer-column align-right">Условия использования</a>
    </div>
  </div>
</footer>
