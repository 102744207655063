import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CMSFormControll, CMSFormGroup, createForm } from '@gen/common/CMSForms';
import { SecurityService } from '@cms/services/security.service';
import { MatDialog } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { equalsPasswordValidator } from '@cms/common/EqualsPasswordValidator';
import { SuccessDialogComponent } from '../../login-register/success-dialog/success-dialog.component';
import { PassErrorStateMatcher } from '@cms/common/pass-error-state.matcher';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-restore-password',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
  ],
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.css'],
})
export class RestorePasswordComponent {
  passErrorStateMatcher = new PassErrorStateMatcher();
  form = createForm(ChangePasswordForm);

  key?: string;

  constructor(
    private service: SecurityService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.form.password.addValidators([Validators.required, Validators.minLength(7)]);
    this.form.password2.addValidators([Validators.required, Validators.minLength(7)]);
    this.form.addValidators(equalsPasswordValidator());

    this.route.params.subscribe(params => {
      this.key = params['key'];
    });
  }

  changePassword() {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();

    if (!this.key) return;

    if (this.form.valid) {
      this.service.restorePasswordFinalStep(this.key, this.form.password.value).subscribe({
        next: responce => {
          this.dialog.closeAll();
          let ref = this.dialog.open(SuccessDialogComponent, { data: 'Пароль успешно изменен' });
          ref.afterClosed().subscribe(_ => {
            this.router.navigate(['/lk/'], { replaceUrl: true });
          });
        },
        error: () => {
          this.dialog.closeAll();
          let ref = this.dialog.open(SuccessDialogComponent, {
            data: 'Ссылка на смену пароля не актуальна, пройдите процедуру восстановления заново',
          });
          ref.afterClosed().subscribe(_ => {
            this.router.navigate(['/'], { replaceUrl: true });
          });
        },
      });
    }
  }
}

class ChangePasswordForm extends CMSFormGroup {
  email = new CMSFormControll();
  oldPassword = new CMSFormControll();
  password = new CMSFormControll();
  password2 = new CMSFormControll();
}
