import {FormControl, FormGroup} from "@angular/forms";

export class CMSFormControll extends FormControl {
    error(errorCode: string) {
        if (this.valid) return false
        return !!this.errors?.[errorCode] && (this.touched || this.dirty)
    }
}

export class CMSFormGroup extends FormGroup {
    constructor() {
        super({});
    }

    error(errorCode: string) {
        if (this.valid) return false
        let errorData = this.errors?.[errorCode];

        if (typeof (errorData) == 'object') {
            let control = this.controls[errorData['field']];
            return !!errorData['hasError'] && (control.touched || control.dirty)
        } else {
            return !!errorData && (this.touched || this.dirty)
        }
    }
}

export function createForm<T extends CMSFormGroup>(TCreator: { new(): T; }): T {
    let instance = new TCreator();
    Object.getOwnPropertyNames(instance)
        .forEach(el => {
            // @ts-ignore
            let prop = instance[el];
            if (prop instanceof FormControl || prop instanceof FormGroup) {
                instance.addControl(el, prop)
            }
        })

    return instance
}
