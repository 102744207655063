import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { RouterOutlet } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { authInterceptor } from '@cms/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeRu from '@angular/common/locales/ru';
import { NgOptimizedImage, registerLocaleData } from '@angular/common';

import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './main/main.component';
import { FooterComponent } from './components/footer/footer.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AboutComponent } from './pages/about/about.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { LoginRegisterComponent } from './login-register/login-register.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorIntlRus } from './ruTranslate';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InRoleDirective } from '@cms/directives/InRoleDirective';
import { MatDividerModule } from '@angular/material/divider';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ContaierSizeToCyrillicPipe } from './components/pipes/contaier-size-to-cyrillic.pipe';
import { MarkdownModule } from 'ngx-markdown';
import { EmptySearchComponent } from '@common/empty-search/empty-search.component';
import { RoutsSearchFormComponent } from '@common/routs-search-form/routs-search-form.component';
import { ContactsCellComponent } from '@common/contacts-cell/contacts-cell.component';
import { RoutsTableComponent } from './pages/routs-table/routs-table.component';

registerLocaleData(localeRu);

@NgModule({
  declarations: [AppComponent],
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlRus },
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterOutlet,
    AppRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    NgOptimizedImage,
    MatDialogModule,
    MatTabsModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatButtonToggleModule,
    MatSelectModule,
    FormsModule,
    RoutsSearchFormComponent,
    ContactsCellComponent,
    MatSnackBarModule,
    InRoleDirective,
    MatFormFieldModule,
    MatDividerModule,
    ContaierSizeToCyrillicPipe,
    HeaderComponent,
    MainComponent,
    FooterComponent,
    AboutComponent,
    FaqComponent,
    ContactsComponent,
    LoginRegisterComponent,
    RoutsTableComponent,
    PageNotFoundComponent,
    EmptySearchComponent,
    CookieConsentComponent,
    ConfirmationDialogComponent,
    MarkdownModule.forRoot(),
  ],
})
export class AppModule {}
