import { Component } from '@angular/core';
import { RoutsTableComponent } from '../routs-table/routs-table.component';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  standalone: true,
  imports: [NgOptimizedImage, RoutsTableComponent],
})
export class MainComponent {}
