import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserTokenService {
  public afterAuthRedirectUrl?: string = '/lk';
  private sessionVarName = 'CMSSession';
  private storage: Storage;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(platformId)) {
      this.storage = localStorage;
    } else {
      this.storage = new FakeLocalStorage();
    }
  }

  getToken(): string {
    let session = this.storage.getItem(this.sessionVarName) || '';
    return `Bearer ${session}`;
  }

  clearToken() {
    this.storage.removeItem(this.sessionVarName);
  }

  setToken(authorization: string) {
    this.storage.setItem(this.sessionVarName, authorization);
  }
}

class FakeLocalStorage implements Storage {
  getItem(key: string) {
    return '';
  }

  setItem(token: string) {}

  removeItem(key: string) {}

  [name: string]: any;

  readonly length: number = 0;

  clear(): void {}

  key(index: number): string | null {
    return '';
  }
}
