<div class="form__login-register">
  <mat-tab-group
    #matTabGroup
    mat-stretch-tabs="false"
    mat-align-tabs="center"
    class="w-100"
    [ngClass]="{ 'hide-tabs': showResetPassword() }"
  >
    <mat-tab label="Вход">
      <app-login-form
        (openRestorePasswordForm)="showResetPassword.set(true)"
      ></app-login-form>
    </mat-tab>
    <mat-tab label="Регистрация">
      <app-registration-form></app-registration-form>
    </mat-tab>
    <mat-tab *ngIf="showResetPassword()">
      <app-reset-password-form
      (hideRestorePasswordForm)="showResetPassword.set(false)"
      ></app-reset-password-form>
    </mat-tab>
  </mat-tab-group>
</div>
