import {ServiceType} from "./service-type"
import {ContainerType} from "./container-type"
import {ContainerSize} from "./container-size"
import {ContactPublicDTO} from "./contact-public-dto"
// @ts-ignore
import {parseDate} from "./base-entity"


export class ServicePublicDTO   {
     updateDate?:Date
     companyName?:string
     serviceType?:ServiceType
     containerType?:ContainerType
     containerSize?:ContainerSize
     cityFrom?:string
     cityTo?:string
     price?:number
     contacts?:Array<ContactPublicDTO>
     comment?:string


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem
        { //isolate scope just in case
            let serviceType = this.serviceType as unknown as string
            this.serviceType = ServiceType[serviceType as keyof typeof ServiceType]
        }
        { //isolate scope just in case
            let containerType = this.containerType as unknown as string
            this.containerType = ContainerType[containerType as keyof typeof ContainerType]
        }
        { //isolate scope just in case
            let containerSize = this.containerSize as unknown as string
            this.containerSize = ContainerSize[containerSize as keyof typeof ContainerSize]
        }

       if (this.updateDate) {
           this.updateDate = parseDate (this.updateDate)
       }




         if (this.contacts) {
             this.contacts = this.contacts.map(el=> ContactPublicDTO.prototype.prepare.call(el))
         }

        return this
    }

}

