import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {Page, RestClientService, RestResponseWrapper, unwrapResponse} from "./rest-client.service";

import {LkCompanyInfoDTO} from "../dto/lk-company-info-dto";



@Injectable({
    providedIn: 'root'
})
export class LkCompanyInfoDTOApi extends RestClientService<LkCompanyInfoDTO> {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected getReqPath(): string {
        return "lk-company-info-dto";
    }

    protected getGenericType(): any {
        return LkCompanyInfoDTO;
    }

}