import { Pipe, PipeTransform } from '@angular/core';
import { ServiceType } from '@dto/service-type';

@Pipe({
  name: 'serviceTypeToCyrillic',
  standalone: true,
})
export class ServiceTypeToCyrillicPipe implements PipeTransform {
  transform(value: ServiceType | undefined, ...args: unknown[]): string {
    switch (value) {
      case ServiceType.AUTO:
        return 'Автотранспорт';
      case ServiceType.TERMINAL_RX:
        return 'Терминал на прием';
      case ServiceType.TERMINAL_TX:
        return 'Терминал на отправку';
      case ServiceType.AUTO_TERMINAL:
        return 'Авто+Терминал';
      case ServiceType.CONTAINER_RENT:
        return 'Аренда контейнеров';
      default:
        return '';
    }
  }
}
