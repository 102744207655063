import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { SeoService } from '@cms/services/seo.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginRegisterComponent } from './login-register/login-register.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  currentUrl!: string;

  constructor(
    public _router: Router,
    private seoService: SeoService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationEnd) {
        this.currentUrl = routerEvent.url;
        if (routerEvent.url === '/login') {
          this.openLoginDialog();
        }
      }
      if (isPlatformBrowser(platformId)) {
        window.scrollTo(0, 0);
      }
    });
  }

  openLoginDialog(): void {
    this.dialog.open(LoginRegisterComponent, {
      width: '450px',
    });
  }
}
