import { Component } from '@angular/core';
import { SecurityService } from '@cms/services/security.service';
import { SecurityApi } from '@api/security-api';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { emailRegexp } from '@cms/common/ErrorHelper';
import { CMSFormControll, CMSFormGroup, createForm } from '@gen/common/CMSForms';
import { Router, RouterLink } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { equalsPasswordValidator } from '@cms/common/EqualsPasswordValidator';
import { UserTokenService } from '@cms/services/user-token.service';
import { PassErrorStateMatcher } from '@cms/common/pass-error-state.matcher';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, NgIf } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    NgIf,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    FormsModule,
    MatCheckboxModule,
    RouterLink,
  ],
})
export class LoginRegisterComponent {
  public passErrorStateMatcher = new PassErrorStateMatcher();
  public loginForm!: LoginForm;
  public registrationForm!: RegistrationForm;
  public resetPasswordForm!: ResetPasswordForm;

  public showResetPasswordForm = false;
  public showResetPasswordSuccess = false;

  constructor(
    private securityService: SecurityService,
    private userTokenService: UserTokenService,
    private securityApi: SecurityApi,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.loginForm = createForm(LoginForm);

    this.loginForm.username.addValidators([Validators.required, Validators.pattern(emailRegexp)]);
    this.loginForm.password.addValidators([Validators.required]);

    this.registrationForm = createForm(RegistrationForm);
    this.registrationForm.username.addValidators([Validators.required, Validators.pattern(emailRegexp)]);
    this.registrationForm.password.addValidators([Validators.required, Validators.minLength(7)]);
    this.registrationForm.password2.addValidators([Validators.required, Validators.minLength(7)]);
    this.registrationForm.checkBox.addValidators([Validators.requiredTrue]);
    this.registrationForm.addValidators(equalsPasswordValidator());

    this.resetPasswordForm = createForm(ResetPasswordForm);
    this.resetPasswordForm.email.addValidators([Validators.required, Validators.pattern(emailRegexp)]);
  }

  doLogin() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) return;

    this.securityService
      .login(this.loginForm.username.value, this.loginForm.password.value)
      .pipe(
        catchError((err, caught) => {
          if (err.code == 'NotExistsException') {
            this.loginForm.username.setErrors(
              Object.assign({ NotExistsException: true }, this.loginForm.username.errors)
            );
            return EMPTY;
          } else if (err.code == 'AuthException') {
            this.loginForm.password.setErrors(
              Object.assign({ AuthException: true }, this.loginForm.password.errors)
            );
            return EMPTY;
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe(userInfo => {
        this.dialog.closeAll();
        this.router.navigateByUrl(this.userTokenService.afterAuthRedirectUrl || '/lk/company-data');
      });
  }

  doRegister() {
    this.registrationForm.markAllAsTouched();
    if (this.registrationForm.invalid) return;

    this.securityApi
      .registration(this.registrationForm.username.value, this.registrationForm.password.value)
      .pipe(
        catchError(err => {
          if (err.code == 'AlreadyExistsException') {
            this.snackBar.open('Данный пользователь уже зарегистрирован', 'Close', {
              duration: 3000,
            });
            this.registrationForm.reset();
            return EMPTY;
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe(userInfo => {
        this.dialog.closeAll();

        const dialogRef = this.dialog.open(SuccessDialogComponent, { data: 'Благодарим за регистрацию!' });

        dialogRef.afterClosed().subscribe(() => {});
      });
  }

  doResetPassword() {
    this.resetPasswordForm.markAllAsTouched();
    if (this.resetPasswordForm.invalid) return;

    this.securityService.restorePasswordFirstStep(this.resetPasswordForm.email.value).subscribe(_ => {
      this.showResetPasswordSuccess = true;
      this.showResetPasswordForm = false;
    });
  }

  closeResetPasswordSuccess() {
    this.dialog.closeAll();

    this.showResetPasswordSuccess = false;
    this.showResetPasswordForm = false;
  }

  toggleResetPasswordForm() {
    this.showResetPasswordForm = !this.showResetPasswordForm;
  }
}

class LoginForm extends CMSFormGroup {
  username = new CMSFormControll();

  password = new CMSFormControll();
}

class RegistrationForm extends LoginForm {
  password2 = new CMSFormControll();

  checkBox = new CMSFormControll();
}

class ResetPasswordForm extends CMSFormGroup {
  email = new CMSFormControll();
}
