<form class="example-form" [formGroup]="formGroup">
  <mat-form-field style="width: 100%">
    <mat-label>{{ label }}</mat-label>
    <input
      #input
      type="text"
      matInput
      formControlName="formControl"
      [errorStateMatcher]="stateMatcher"
      [matAutocomplete]="auto"
      (input)="filter()"
      (focusin)="filter()"
    />
    <mat-autocomplete (optionSelected)="onSelected($event)" requireSelection #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions" [value]="option">
        {{ option.value }}
      </mat-option>
    </mat-autocomplete>
    <button
      *ngIf="formGroup.controls['formControl'].value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearSelections()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-error *ngIf="errorState">Обязательное поле</mat-error>
  </mat-form-field>
</form>
