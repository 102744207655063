// @ts-ignore
import {parseDate} from "./base-entity"


export class ContactPublicDTO   {
     contactName?:string
     contactPhone?:string
     contactEmail?:string


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem






        return this
    }

}

