import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicePublicDTO } from '@dto/service-public-dto';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MobileCardComponent } from './mobile-card/mobile-card.component';
import { PublicServiceView } from './public-service.view';
import { DesktopCardComponent } from './desktop-card/desktop-card.component';
import { DesktopCard2Component } from '@common/rout-card/desktop-card2/desktop-card2.component';

@Component({
  selector: 'app-routs-card',
  templateUrl: './routs-card.component.html',
  styleUrls: ['./routs-card.component.scss'],
  standalone: true,
  imports: [CommonModule, MobileCardComponent, DesktopCardComponent, DesktopCard2Component],
})
export class RoutsCardComponent {
  [x: string]: any;

  isMobile: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  view!: PublicServiceView;
  @Input()
  showCompanyInfoLink: boolean = true;

  @Input()
  set service(value: ServicePublicDTO) {
    this.view = Object.assign(new PublicServiceView(), value);
  }
}
