export interface MetaDTO {
  title: string;
  desc: string;
  icon: string;
}

let staticMeta: any = {
  '/': {
    title: 'Главная',
    desc:
      'Сервис ISZ позволяет найти железнодорожные контейнерные грузоперевозки по основным направлениям России в пару кликов. ' +
      'Воспользуйтесь фильтром, чтобы задать параметры и найти нужную услугу.',
  },
  '/requests': {
    title: 'Заявки',
    desc:
      'Сервис ISZ позволяет найти железнодорожные контейнерные грузоперевозки по основным направлениям России в пару кликов. ' +
      'Воспользуйтесь фильтром, чтобы задать параметры и найти нужную услугу.',
  },
  '/about': {
    title: 'О нас',
    desc:
      'Компания ISZ обеспечивает взаимодействие между компаниями-перевозчиками и конечным клиентом. ' +
      'Мы предлагаем удобное и быстрое решение для оптимизации ваших бизнес-процессов.',
  },
  '/faq': {
    title: 'Частые вопросы',
    desc:
      'Мы заботимся о наших клиентах, поэтому создали страницу с частыми вопросами и ответами на них, ' +
      'Выберите интересующий раздел, чтобы решить свою проблему.',
  },
  '/subscription': {
    title: 'Подписка',
    desc:
      'Подписка дает возможность просматривать информацию интересующей услуги. ' +
      'Выберите удобный тариф, чтобы узнать детали услуги.',
  },
  '/contacts': {
    title: 'Контакты',
    desc:
      'Мы рады обратной связи наших партнеров и открыты для предложений. ' +
      'Позвоните или напишите нам на электронную почту.',
  },
  '/combined': {
    title: 'Сборные перевозки',
    desc: 'Сборный груз перевозится совместно с другими грузами, что обеспечивает экономическую эффективность и возможность доставки даже для небольших отправлений.',
  },
  '/terms': {
    title: 'Политика конфиденциальности',
    desc: 'Сервис ISZ обеспечивает безопасность и конфиденциальность данных пользователей.',
  },
  '/lk/company-data': {
    title: 'Данные компании',
    desc: 'Заполните данные компании, чтобы ваши услуги были видным клиентам.',
  },
  '/lk/employee-contacts': {
    title: 'Контакты сотрудников',
    desc: 'Закрепите услугу за сотрудником, который будет взаимодействовать с клиентами.',
  },
  '/lk/your-subscription': {
    title: 'Ваша подписка',
    desc: 'На данной странице представлена информация о вашем текущем тарифе подписки.',
  },
  '/lk/your-services': {
    title: 'Ваши услуги',
    desc: 'Создайте и опубликуйте услугу в Личном кабинете, чтобы ваши клиенты видели вас в общем списке.',
  },
  '/lk/issued-invoices': {
    title: 'Выставленные счета',
    desc: 'Все оформленные счета с актуальными статусами доступны для мониторинга в данном разделе.',
  },
  '/lk/change-password': {
    title: 'Смена пароля',
    desc: 'Изменение пароля доступно в данном разделе.',
  },
};

export function getMeta(key: string): MetaDTO {
  return staticMeta[key] || staticMeta['/'];
}
