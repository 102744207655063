<div class="app-container">
  <div [class.collapsed]="menuCollapsed" class="menu-container">
    <div class="menu">
      <div class="toggle-button" (click)="menuCollapsed = !menuCollapsed">
        <svg
          class="menu-icon"
          [class.rotated]="menuCollapsed"
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
        >
          <path
            d="M6 1.03955L1 6.03955L6 11.0396"
            stroke="#747474"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="menu-items">
        <a
          class="nav-link"
          routerLink="/lk/company-data"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <mat-icon svgIcon="company-data" aria-hidden="false"></mat-icon>
          <span class="text">Данные компании</span>
        </a>
        <a
          class="nav-link"
          routerLink="/lk/employee-contacts"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <mat-icon svgIcon="employee-contacts" aria-hidden="false"></mat-icon>
          <span class="text">Контакты сотрудников</span>
        </a>
        <a
          class="nav-link"
          routerLink="/lk/your-subscription"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <mat-icon svgIcon="your-subscription" aria-hidden="false"></mat-icon>
          <span class="text">Ваша подписка</span>
        </a>
        <a
          class="nav-link"
          #yourServicesLink="routerLinkActive"
          routerLink="/lk/your-services/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <mat-icon svgIcon="your-services" aria-hidden="false"></mat-icon>
          <span class="text">Ваши услуги</span>
        </a>
        <div
          class="submenu"
          *ngIf="yourServicesLink.isActive || (currentServiceType && currentServiceType.length > 0)"
        >
          <a
            class="nav-link"
            [routerLink]="['/lk/your-services', 'AUTO']"
            [queryParams]="{ serviceType: ServiceTypes[ServiceTypes.AUTO] }"
            [class.active]="isServiceActive('AUTO')"
          >
            <span class="text">Автодоставка</span>
          </a>
          <a
            class="nav-link"
            [routerLink]="['/lk/your-services', 'TERMINAL_RX']"
            [queryParams]="{ serviceType: ServiceTypes[ServiceTypes.TERMINAL_RX] }"
            [class.active]="isServiceActive('TERMINAL_RX')"
          >
            <span class="text">Терминал на прием</span>
          </a>
          <a
            class="nav-link"
            [routerLink]="['/lk/your-services', 'TERMINAL_TX']"
            [queryParams]="{ serviceType: ServiceTypes[ServiceTypes.TERMINAL_TX] }"
            [class.active]="isServiceActive('TERMINAL_TX')"
          >
            <span class="text">Терминал на отправку</span>
          </a>
          <a
            class="nav-link"
            [routerLink]="['/lk/your-services', 'AUTO_TERMINAL']"
            [queryParams]="{ serviceType: ServiceTypes[ServiceTypes.AUTO_TERMINAL] }"
            [class.active]="isServiceActive('AUTO_TERMINAL')"
          >
            <span class="text">Авто + Терминал</span>
          </a>
        </div>
        <a
          class="nav-link"
          routerLink="/lk/issued-invoices"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <mat-icon svgIcon="issued-invoices" aria-hidden="false"></mat-icon>
          <span class="text">Выставленные счета</span>
        </a>
        <a
          class="nav-link"
          routerLink="/lk/change-password"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <mat-icon svgIcon="change-password" aria-hidden="false"></mat-icon>
          <span class="text">Сменить пароль</span>
        </a>
        <a
          *inRole="'ROLE_MANAGER'"
          class="nav-link"
          routerLink="/lk/admin"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="text">Админка</span>
        </a>
      </div>
      <a class="nav-link logout-link" (click)="logout()">
        <span class="text">Выйти</span>
      </a>
    </div>
  </div>
  <div class="content-container">
    <router-outlet></router-outlet>
  </div>
</div>
