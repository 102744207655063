import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { SecurityService } from '../services/security.service';
import { distinctUntilChanged, Observable } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from '@gen/common/UnsubscribeOnDestroyAdapter';

@Directive({
  selector: '[inRole]',
  standalone: true,
})
export class InRoleDirective extends UnsubscribeOnDestroyAdapter implements OnDestroy {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private securityService: SecurityService
  ) {
    super();
  }
  private observable!: Observable<boolean>;

  @Input()
  set inRole(role: string) {
    this.viewContainer.clear();

    this.observable = this.securityService.inRole(role).pipe(distinctUntilChanged());

    this.subs.sink = this.observable.subscribe(inRole => {
      if (inRole) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }

  @Input()
  set inRoleElse(ref: TemplateRef<any>) {
    this.subs.sink = this.observable.subscribe(inRole => {
      if (!inRole) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(ref);
      }
    });
  }
}
