import {BaseEntity} from "./base-entity"
// @ts-ignore
import {parseDate} from "./base-entity"


export class Tariffs extends BaseEntity  {
     name?:string
     price?:number
     features?:string
     duration?:number
     archived?:boolean


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem



            BaseEntity.prototype.prepare.apply(this)



        return this
    }

}

