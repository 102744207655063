<div class="cookie-consent" *ngIf="!cookieConsentGiven">
  <h2>
    Мы используем cookie-файлы,<br />
    чтобы улучшить наш сайт для Вас.
  </h2>
  <p>
    Вся персональная информация обрабатывается <br/>
    в соответствии с <a routerLink="/private-info">использования сервиса</a>.
  </p>
  <button mat-raised-button color="primary" (click)="agree()">Согласен</button>
</div>
