<div [ngClass]="{ 'subs-container': useStyledContainer }">
  <h1>Все выгодные предложения в одном месте</h1>
  <p>Выберите удобный тарифный план для работы с заявками:</p>

  <div class="cards-container">
    <mat-card class="card" *ngFor="let tier of tiers">
      <mat-card-header class="card__header">
        <mat-card-title class="card__header-title">{{ tier.name }}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="card__body">
        <p class="card__body-header">{{ tier.header }}</p>
        <ul class="card__body-text">
          <li *ngFor="let feature of tier.features">{{ feature }}</li>
        </ul>
        <p class="card__body-price">{{ tier.price }}</p>
        <button mat-raised-button color="primary" (click)="onSelect(tier.id)" class="card__body-button">
          Купить
        </button>
      </mat-card-content>
    </mat-card>
  </div>
  <div>Оплачивая счёт вы соглашаетесь с <a class="icon-link" routerLink='/services-agreement'>Договором Офертой</a>
  </div>
</div>
