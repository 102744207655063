import {OwnershipOfCompanyType} from "./ownership-of-company-type"
import {Autocomplete, AutoOutput} from "@gen/common/Autocomplete"
// @ts-ignore
import {parseDate} from "./base-entity"


export class LkCompanyInfoDTO  implements AutoOutput {
     id?:number
     formOfOwnership?:OwnershipOfCompanyType
     address?:string
     fio?:string
     name?:string
     email?:string
     inn?:string
     phone?:string
     kpp?:string
     ogrnDate?:Date


    get value() { return this.name!!};


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem
        { //isolate scope just in case
            let formOfOwnership = this.formOfOwnership as unknown as string
            this.formOfOwnership = OwnershipOfCompanyType[formOfOwnership as keyof typeof OwnershipOfCompanyType]
        }

       if (this.ogrnDate) {
           this.ogrnDate = parseDate (this.ogrnDate)
       }





        return this
    }

}

