import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { InRoleDirective } from '@cms/directives/InRoleDirective';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LkCompanyInfoDTOApi } from '@api/lk-company-info-dto.service';
import { SecurityService } from '@cms/services/security.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { LoginRegisterComponent } from '../../../login-register/login-register.component';

@Component({
  selector: 'app-profile-button',
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterLink, InRoleDirective],
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss'],
})
export class ProfileButtonComponent {
  userFio!: Observable<string>;
  userFioCache = new BehaviorSubject<string | null>(null);

  constructor(
    public dialog: MatDialog,
    private api: LkCompanyInfoDTOApi,
    private securityService: SecurityService
  ) {
    this.userFio = this.userFioCache.asObservable().pipe(
      switchMap(cachedFio => {
        if (cachedFio !== null) {
          return of(cachedFio);
        } else {
          return this.securityService.updateRoleSubject.pipe(
            switchMap(userRole => {
              if (userRole !== 'ROLE_ANONYMOUS') {
                return this.api.getOne({}).pipe(
                  map(
                    el =>
                      el?.fio
                        ?.split(' ')
                        .map(name => name[0])
                        .join('')
                        .toUpperCase() || ''
                  ),
                  tap(fio => this.userFioCache.next(fio))
                );
              } else {
                return of('');
              }
            })
          );
        }
      })
    );
  }

  openLoginDialog(): void {
    this.dialog.open(LoginRegisterComponent, {
      width: '450px',
      position: { top: '25vh' },
    });
  }
}
