import { inject, InjectionToken } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from './services/security.service';
import { map } from 'rxjs/operators';

export const RoleGuard = (
  role: string
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const securityService = inject(SecurityService);
  const router = inject(Router);
  return securityService.inRole(role).pipe(map(inRole => (inRole ? true : router.parseUrl('/login'))));
};

export const ROLE_GUARD_INJECTION_TOKEN = new InjectionToken<
  (role: string) => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
>('roleGuardInjectionToken', {
  providedIn: 'root',
  factory: () => RoleGuard,
});
