import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterLinkActive, UrlTree } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { distinctUntilChanged, forkJoin, Observable, of, reduce } from 'rxjs';

type MenuViewGuardReturnType = boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>;
export type MenuViewGuard = () => () => Observable<boolean>;

export interface MenuNodeData {
  icon: string;
  text: string;
  link?: string[];
  queryParams?: { [key: string]: string };
  subs?: MenuNodeData[];
  canShow?: MenuViewGuard;
  events?: { [key: string]: any };
}

@Component({
  selector: 'app-menu-node',
  standalone: true,
  imports: [CommonModule, RouterLinkActive, RouterLink, MatIconModule],
  templateUrl: './menu-node.component.html',
  styleUrls: ['./menu-node.component.scss'],
})
export class MenuNodeComponent implements OnChanges {
  @Input({
    required: true,
  })
  data!: MenuNodeData;
  canViewSignal = signal<boolean>(false);

  @Input()
  isCollapsed: boolean = false;

  @Output()
  events = new EventEmitter<{ key: string; data: any }>();

  isSubMenuCollapsed: boolean = true;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      let value = changes['data'].currentValue;
      if (!value.canShow) {
        this.canViewSignal.set(true);
      } else {
        value.canShow().subscribe((value: boolean) => {
          this.canViewSignal.set(value);
        });
      }
    }
  }

  nodeClicked() {
    if (this.data && this.data.subs && this.data.subs.length) {
      this.isSubMenuCollapsed = !this.isSubMenuCollapsed;
    }
    if (this.data.events) {
      if (this.data.events['click']) {
        this.events.emit({ key: 'click', data: this.data.events['click'] });
      }
    }
  }
}
