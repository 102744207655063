import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CMSFormControll, CMSFormGroup, createForm } from '@gen/common/CMSForms';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { emailRegexp } from '@cms/common/ErrorHelper';
import { SecurityService } from '@cms/services/security.service';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-reset-password-form',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule, MatButtonModule, MatInputModule],
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.css'],
})
export class ResetPasswordFormComponent {
  @Output()
  hideRestorePasswordForm = new EventEmitter();

  public resetPasswordForm!: ResetPasswordForm;

  public showResetPasswordSuccess = false;

  constructor(
    private securityService: SecurityService,
    private dialog: MatDialog
  ) {
    this.resetPasswordForm = createForm(ResetPasswordForm);
    this.resetPasswordForm.email.addValidators([Validators.required, Validators.pattern(emailRegexp)]);
  }

  doResetPassword() {
    this.resetPasswordForm.markAllAsTouched();
    if (this.resetPasswordForm.invalid) return;

    this.securityService.restorePasswordFirstStep(this.resetPasswordForm.email.value).subscribe(_ => {
      this.closeResetPasswordSuccess();
    });
  }

  closeResetPasswordSuccess() {
    this.dialog.closeAll();
    this.showResetPasswordSuccess = false;
  }
}

class ResetPasswordForm extends CMSFormGroup {
  email = new CMSFormControll();
}
