import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAutocompleteService } from '@gen/services/BaseAutocompleteService';
import { Autocomplete, AutoOutput } from '@gen/common/Autocomplete';
import { Stations } from '@dto/stations';
import { LkCompanyContactsDTO } from '@dto/lk-company-contacts-dto';
import { LkCompanyInfoDTO } from '@dto/lk-company-info-dto';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService extends BaseAutocompleteService {
  constructor(http: HttpClient) {
    super(http);
  }

  forStations(): Autocomplete {
    return this.factory('/stations', (el: AutoOutput) => {
      let dto = new Stations();
      Object.assign(dto, el);
      return dto.prepare();
    });
  }

  forCompanyInfo(): Autocomplete {
    return this.factory('/companies', (el: AutoOutput) => {
      let dto = new LkCompanyInfoDTO();
      Object.assign(dto, el);
      return dto.prepare();
    });
  }

  forContacts(): Autocomplete {
    return this.factory('/contacts', (el: AutoOutput) => {
      let dto = new LkCompanyContactsDTO();
      Object.assign(dto, el);
      return dto.prepare();
    });
  }
}
