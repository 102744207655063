import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ServicePublicDTO } from '@dto/service-public-dto';
import { ServicePublicDTOApi } from '@api/service-public-dto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchFilters } from './routs-search-form/search-filters';
import { ContainerSize } from '@dto/container-size';
import { ContainerType } from '@dto/container-type';
import { UnsubscribeOnDestroyAdapter } from '@gen/common/UnsubscribeOnDestroyAdapter';
import { ContaierSizeToCyrillicPipe } from '../components/pipes/contaier-size-to-cyrillic.pipe';
import { EmptySearchComponent } from './empty-search/empty-search.component';
import { ContactsCellComponent } from './contacts-cell/contacts-cell.component';
import { CurrencyPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { RoutsSearchFormComponent } from './routs-search-form/routs-search-form.component';
import { RoutsCardComponent } from './rout-card/routs-card.component';

@Component({
  selector: 'app-routs-table',
  templateUrl: './routs-table.component.html',
  styleUrls: ['./routs-table.component.css'],
  standalone: true,
  imports: [
    RoutsSearchFormComponent,
    MatTableModule,
    NgIf,
    NgFor,
    ContactsCellComponent,
    EmptySearchComponent,
    MatPaginatorModule,
    CurrencyPipe,
    DatePipe,
    ContaierSizeToCyrillicPipe,
    RoutsCardComponent,
  ],
})
export class RoutsTableComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  ContainerSizes = ContainerSize;
  ContainerTypes = ContainerType;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 20];
  hidePageSize: boolean = false;

  displayedColumns: string[] = [
    'updateDate',
    'route',
    'companyName',
    'contacts',
    'price',
    'containerType',
    'comment',
  ];
  dataSource = new MatTableDataSource<ServicePublicDTO>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  filters: SearchFilters = new SearchFilters();

  ngOnInit() {
    this.subs.sink = this.route.queryParams.subscribe(params => {
      const pageIndex = params['page'] ? parseInt(params['page'], 10) : 0;
      const pageSize = params['size'] ? parseInt(params['size'], 10) : this.pageSize;
      this.filters.fromQueryParams(params);
      this.load(pageIndex, pageSize, this.filters);
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  services: ServicePublicDTO[] = [];

  constructor(
    private servicesApi: ServicePublicDTOApi,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  load(pageIndex: number, pageSize: number, filters: SearchFilters) {
    this.servicesApi
      .getAll({ page: pageIndex, size: pageSize, filters: filters.toCriteriaFilters() })
      .subscribe(page => {
        this.services = page.content;
        this.dataSource.data = this.services;
        this.length = page.totalElements;
        this.pageSize = page.size;
        this.pageIndex = page.number;

        /*        if (this.paginator) {
          this.paginator.pageIndex = this.pageIndex;
          this.paginator.pageSize = this.pageSize;
          this.paginator.length = this.length;
        }*/
      });
  }

  onPaginatorEvent(event: PageEvent) {
    let queryParams = {
      ...this.route.snapshot.queryParams,
      page: event.pageIndex,
      size: event.pageSize,
    };
    this.router.navigate(['.'], { queryParams, relativeTo: this.route });
  }

  doSearch(filters: SearchFilters) {
    let params = filters.toQueryParams();

    if (filters.serviceType != this.filters.serviceType) {
      params['page'] = 0;
    } else {
      params['page'] = this.paginator?.pageIndex ?? 0;
    }

    params['size'] = this.paginator?.pageSize ?? 10;

    this.router.navigate(['.'], { queryParams: params, relativeTo: this.route });
  }
}
