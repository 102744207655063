import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CMSFormControll, CMSFormGroup, createForm } from '@gen/common/CMSForms';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { emailRegexp } from '@cms/common/ErrorHelper';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { SecurityService } from '@cms/services/security.service';
import { Router } from '@angular/router';
import { UserTokenService } from '@cms/services/user-token.service';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDividerModule,
  ],
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent {
  public loginForm!: LoginForm;

  @Output()
  openRestorePasswordForm = new EventEmitter();

  constructor(
    private router: Router,
    private userTokenService: UserTokenService,
    private securityService: SecurityService,
    private dialog: MatDialog
  ) {
    this.loginForm = createForm(LoginForm);

    this.loginForm.username.addValidators([Validators.required, Validators.pattern(emailRegexp)]);
    this.loginForm.password.addValidators([Validators.required]);
  }

  doLogin() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) return;

    this.securityService
      .login(this.loginForm.username.value, this.loginForm.password.value)
      .pipe(
        catchError((err, caught) => {
          if (err.code == 'NotExistsException') {
            this.loginForm.username.setErrors(
              Object.assign({ NotExistsException: true }, this.loginForm.username.errors)
            );
            return EMPTY;
          } else if (err.code == 'AuthException') {
            this.loginForm.password.setErrors(
              Object.assign({ AuthException: true }, this.loginForm.password.errors)
            );
            return EMPTY;
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe(userInfo => {
        this.dialog.closeAll();
        this.router.navigateByUrl(this.userTokenService.afterAuthRedirectUrl || '/lk/company-data');
      });
  }
}

export class LoginForm extends CMSFormGroup {
  username = new CMSFormControll();
  password = new CMSFormControll();
}
