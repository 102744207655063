const backend = 'https://dev.emoz.fi';
const backEndService = backend + '/login/cas';
const apiVersion = 'v1';

export const environment = {
  production: false,
  apiVersion: apiVersion,
  apiUrl: backend + '/api/' + apiVersion,
  backEndServiceName: backEndService,
  backend: backend,
  backendAuthEndpoint: backEndService + '?ticket=',
};
