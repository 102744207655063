import {LoginDTO} from "./login-dto"
// @ts-ignore
import {parseDate} from "./base-entity"


export class RegistrationDTO extends LoginDTO  {


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem



            LoginDTO.prototype.prepare.apply(this)



        return this
    }

}

