import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { SecurityService } from '../services/security.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[inRole]',
  standalone: true,
})
export class InRoleDirective implements OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private securityService: SecurityService
  ) {}

  private hasPermission?: Promise<boolean>;

  @Input()
  set inRole(role: string) {
    this.viewContainer.clear();
    this.hasPermission = new Promise<boolean>(resolve => {
      this.subscription.add(
        this.securityService.updateRoleSubject.subscribe(async () => {
          let hasPermission = await this.securityService.inRole(role);
          resolve(hasPermission);
          if (hasPermission) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        })
      );
    });
  }

  @Input()
  set inRoleElse(ref: TemplateRef<any>) {
    if (this.hasPermission) {
      this.hasPermission?.then(value => {
        if (!value) this.viewContainer.createEmbeddedView(ref);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
