import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {Page, RestClientService, RestResponseWrapper, unwrapResponse} from "./rest-client.service";

import {Tariffs} from "../dto/tariffs";


@Injectable({
  providedIn: 'root'
})
export class TariffsApi extends RestClientService<Tariffs> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  protected getReqPath(): string {
    return "tariffs";
  }

  protected getGenericType(): any {
    return Tariffs;
  }

  delete(p: Tariffs): Observable<Tariffs> {
    return this.httpClient.post<RestResponseWrapper<Tariffs>>(this.apiUrl + "/delete", p)
      .pipe(map(unwrapResponse),
        map(el => {
          return this.getGenericType().prototype.prepare.apply(el)
        }))
  }
}
