import { Component, Input, OnChanges, OnInit, signal, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ServiceType } from '@dto/service-type';
import {
  CONTAINER_RENT_ICON,
  TERMINAL_IN_ICON,
  TERMINAL_OUT_ICON,
  TRUCK_ICON,
  TRUCK_PLUS_ICON,
} from '@common/routs-search-form/service-type-tabs/IconsData';
import { ServiceTypeToCyrillicPipe } from '../../../../pipes/service-type-to-cyrillic.pipe';

@Component({
  selector: 'app-service-type-cyrillic',
  standalone: true,
  imports: [CommonModule, MatIconModule, ServiceTypeToCyrillicPipe],
  templateUrl: './service-type-cyrillic.component.html',
  styleUrls: ['./service-type-cyrillic.component.css'],
})
export class ServiceTypeCyrillicComponent implements OnInit, OnChanges {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  @Input()
  serviceType?: ServiceType;

  data = signal<{ icon: string; type: ServiceType } | undefined>(undefined);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['serviceType'] && changes['serviceType'].currentValue !== undefined) {
      let currentValue = changes['serviceType'].currentValue as ServiceType;
      this.data.set({
        icon: this.initIcon(currentValue),
        type: currentValue,
      });
    }
  }

  ngOnInit(): void {
    if (this.serviceType) {
      this.data.set({
        icon: this.initIcon(this.serviceType),
        type: this.serviceType,
      });
    }
  }

  private initIcon(currentValue: ServiceType): string {
    switch (currentValue) {
      case ServiceType.AUTO: {
        this.iconRegistry.addSvgIconLiteral('truck', this.sanitizer.bypassSecurityTrustHtml(TRUCK_ICON));
        return 'truck';
      }
      case ServiceType.TERMINAL_RX: {
        this.iconRegistry.addSvgIconLiteral(
          'terminal_in',
          this.sanitizer.bypassSecurityTrustHtml(TERMINAL_IN_ICON)
        );
        return 'terminal_in';
      }
      case ServiceType.TERMINAL_TX: {
        this.iconRegistry.addSvgIconLiteral(
          'terminal_out',
          this.sanitizer.bypassSecurityTrustHtml(TERMINAL_OUT_ICON)
        );
        return 'terminal_out';
      }
      case ServiceType.AUTO_TERMINAL: {
        this.iconRegistry.addSvgIconLiteral(
          'truck_plus',
          this.sanitizer.bypassSecurityTrustHtml(TRUCK_PLUS_ICON)
        );
        return 'truck_plus';
      }
      case ServiceType.CONTAINER_RENT: {
        this.iconRegistry.addSvgIconLiteral(
          'container_rent',
          this.sanitizer.bypassSecurityTrustHtml(CONTAINER_RENT_ICON)
        );
        return 'container_rent';
      }
    }
    return '';
  }
}
