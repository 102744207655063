import { Pipe, PipeTransform } from '@angular/core';
import { ServiceType } from '@dto/service-type';

@Pipe({
  name: 'serviceTypeToCyrillic',
  standalone: true,
})
export class ServiceTypeToCyrillicPipe implements PipeTransform {
  transform(value: ServiceType | undefined, ...args: unknown[]): string {
    switch (value) {
      case ServiceType.AUTO:
        return 'Автодоставка контейнеров';
      case ServiceType.TERMINAL_RX:
        return 'Ж/Д перевозки';
      case ServiceType.TERMINAL_TX:
        return 'Услуги терминала';
      case ServiceType.AUTO_TERMINAL:
        return 'Полный цикл услуг';
      case ServiceType.CONTAINER_RENT:
        return 'Аренда контейнеров';
      default:
        return '';
    }
  }
}
