import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouterModule,
  RouterOutlet,
  RouterLink,
  RouterLinkActive,
  RouterLinkWithHref,
} from '@angular/router';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { filter } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { ServiceType } from '@dto/service-type';
import { SecurityService } from '@cms/services/security.service';
import { InRoleDirective } from '@cms/directives/InRoleDirective';
import { SideMenuComponent } from './side-menu/side-menu.component';

@Component({
  standalone: true,
  selector: 'app-lk',
  templateUrl: './lk.component.html',
  styleUrls: ['./lk.component.css'],
  imports: [RouterOutlet, SideMenuComponent],
})
export class LkComponent {}
