<div class="filter__container">
  <app-route-autocomplete
    [selectedValue]="searchFilter.departure"
    [label]="'Пункт отправления'"
    (onValue)="onDepartureSelected($event)"
  ></app-route-autocomplete>

  <button mat-icon-button (click)="swapLocations()">
    <mat-icon>swap_horiz</mat-icon>
  </button>

  <app-route-autocomplete
    [selectedValue]="searchFilter.destination"
    [label]="'Пункт назначения'"
    (onValue)="onDestinationSelected($event)"
  ></app-route-autocomplete>

  <app-container-type-select
    [selectedOptions]="computeSelected()"
    (onChange)="onContainerTypeChange($event)"
  ></app-container-type-select>

  <button mat-raised-button color="primary" class="filter__search-button" (click)="applyFilter()">
    Поиск
  </button>
</div>
