<div class="faq-container">
  <h1>FAQ</h1>
  <div class="faq-grid">
    <mat-card *ngFor="let faq of faqs" (click)="navigateToFaq(faq.id)" class="faq-card">
      <mat-card-header>
        <div mat-card-avatar class="faq-icon">
          <mat-icon>{{ faq.icon }}</mat-icon>
        </div>
        <mat-card-title>{{ faq.question }}</mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>
</div>
