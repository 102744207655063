import {BehaviorSubject, debounce, interval, Observable, Observer, Subject, Subscription} from "rxjs";
interface AutoInput {
  query: string;
}

export interface AutoOutput {
  id?: number;
  value?: string;
}

export class Autocomplete {
  out = new BehaviorSubject<AutoOutput[]>([]);

  input: Observable<AutoInput>;
  private $input = new Subject<AutoInput>();

  fetch!: (incompleteDTO: AutoOutput) => Observable<AutoOutput>;

  constructor() {
    this.input = this.$input.pipe(debounce(_ => interval(300)))/*.pipe(filter(el => el.query.length >= 1))*/;
  }

  subscribe(observerOrNext?: Partial<Observer<AutoOutput[]>> | ((value: AutoOutput[]) => void)): Subscription {
    return this.out.subscribe(observerOrNext)
  }
  next(query: string) {
    this.$input.next({query: query});
  }
}
