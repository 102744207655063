<mat-card>
  <mat-card-content *inRole="'ROLE_CLIENT'; else forAnon">
    <table class="card-table">
      <tbody class="card-table__header">
      <tr>
        <td class="value date-column">
          <div class="date-container">
            <span class="title">опубликовано</span>
            <span class="date-info">
                <img src="/assets/images/icons/card-icon_date.svg" alt="icon"/>&nbsp;{{
              view.updateDate | date: 'dd.MM.yyyy'
              }}
              </span>
          </div>
        </td>
        <td class="value direction-column">
          <span class="title">маршрут</span><br/>
          <div class="direction-content">
            <img src="/assets/images/flags/rf.png" alt="flag" class="flag"/>
            <span class="directions">{{ view.cityFrom }} - <img style="margin-left: 5px;"
                                                                src="/assets/images/flags/rf.png" alt="flag"
                                                                class="flag"/>{{ view.cityTo }}</span>
          </div>
        </td>
        <td
          class="value container-column"
          *ngIf="view.containerType !== undefined && view.containerSize !== undefined"
        >
          <span class="title"> размер контейнера </span><br/>
          <img src="/assets/images/icons/card-icon_container.svg" alt="icon"/>&nbsp;{{
          ContainerTypes[view.containerType]
          }}/{{ view.containerSize | sizeToCyrillic }}
        </td>
        <td class="value price-column">
          <span class="title">актуальная цена</span><br/>
          <b>{{ view.price | currency: 'RUB' : 'symbol' : '1.0-0' : 'ru' }}</b>
        </td>
      </tr>
      </tbody>
    </table>

    <table class="card-table">
      <tbody class="card-table__body" *ngIf="view.comment; else noComment">
      <tr>
        <td colspan="2" [width]="'50%'" class="title">
          <img src="/assets/images/icons/card-icon_company.svg" alt="icon"/>&nbsp;Компания
        </td>
        <td colspan="2" class="title">
          <img src="/assets/images/icons/card-icon_comment.svg" alt="icon"/>&nbsp;Комментарий
        </td>
      </tr>
      <tr>
        <td colspan="2" class="value">
          {{ view.companyName }}
        </td>
        <td colspan="2" rowspan="4" class="value">
          {{ view.comment }}
        </td>
      </tr>
      <tr>
        <td colspan="4" class="title">
          <img src="/assets/images/icons/card-icon_contact.svg" alt="icon"/>&nbsp;Контакты
        </td>
      </tr>
      <tr>
        <td colspan="4" class="value">
          <app-contacts-cell [contacts]="view.mappedContacts"></app-contacts-cell>
        </td>
      </tr>
      </tbody>
      <ng-template #noComment>
        <tbody class="card-table__body">
        <tr>
          <td colspan="2" [width]="'50%'" class="title">
            <img src="/assets/images/icons/card-icon_company.svg" alt="icon"/>&nbsp;Компания
          </td>
          <td colspan="2" class="title">
            <img src="/assets/images/icons/card-icon_contact.svg" alt="icon"/>&nbsp;Контакты
          </td>
        </tr>
        <tr>
          <td colspan="2" class="value">
            {{ view.companyName }}
          </td>
          <td colspan="2" class="value">
            <app-contacts-cell [contacts]="view.mappedContacts"></app-contacts-cell>
          </td>
        </tr>
        </tbody>
      </ng-template>
    </table>
  </mat-card-content>
  <ng-template #forAnon>
    <mat-card-content>
      <table class="card-table__anonymous">
        <tbody class="card-table__header">
        <tr>
          <td class="value" [width]="'15%'">
            <div class="date-container">
              <span class="title">опубликовано</span>
              <span class="date-info">
                <img src="/assets/images/icons/card-icon_date.svg" alt="icon"/>&nbsp;{{
                view.updateDate | date: 'dd.MM.yyyy'
                }}
              </span>
            </div>
          </td>
          <td [width]="'0%'" class="flag-container">
          </td>

          <td [width]="'50%'" class="value font16"><img src="/assets/images/flags/rf.png" alt="flag"
                                                        class="flag"/> {{ view.cityFrom }}
            <br/>
            <img src="/assets/images/flags/rf.png" alt="flag" class="flag"/>
            {{ view.cityTo }}
          </td>
          <td
            [width]="'15%'"
            class="value"
            *ngIf="view.containerType !== undefined && view.containerSize !== undefined"
          >
            <span class="title">размер контейнера</span><br/>
            <img src="/assets/images/icons/card-icon_container.svg" alt="icon"/>&nbsp;{{
            ContainerTypes[view.containerType]
            }}/{{ view.containerSize | sizeToCyrillic }}
          </td>
          <td class="value subscribe-container">
            <div class="subscribe-content">
              <button class="subscribe-button" (click)="openLoginDialog()">Узнать контакты и ставку
              </button
              >
              <br/>
              <p class="sub-subscribe-button">Информация доступна по подписке</p>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </mat-card-content>
  </ng-template>
</mat-card>
