import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceType } from '@dto/service-type';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterLinkWithHref,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityService } from '@cms/services/security.service';
import { filter } from 'rxjs/operators';
import { InRoleDirective } from '@cms/directives/InRoleDirective';

import { icons } from './icons-data';
import { MenuNodeComponent } from './menu-node/menu-node.component';

import { LK_MENU_DATA_INJECTION_TOKEN } from './menu-data';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    RouterLinkWithHref,
    MatIconModule,
    InRoleDirective,
    MenuNodeComponent,
  ],
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  ServiceTypes = ServiceType;
  menuCollapsed = false;
  currentServiceType: string | null = null;

  menu = inject(LK_MENU_DATA_INJECTION_TOKEN);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private securityService: SecurityService
  ) {
    this.registerIcons();
  }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.activatedRoute.queryParams.subscribe(params => {
        this.currentServiceType = params['serviceType'];
      });
    });
  }

  private updateCurrentServiceType(): void {
    const child = this.activatedRoute.firstChild;

    if (child && child.snapshot && child.snapshot.params) {
      this.currentServiceType = child.snapshot.params['serviceType'];
    } else {
      this.currentServiceType = null;
    }
  }

  private registerIcons(): void {
    Object.keys(icons).forEach(key => {
      this.iconRegistry.addSvgIconLiteral(key, this.sanitizer.bypassSecurityTrustHtml(icons[key]));
    });
  }

  isServiceActive(type: string): boolean {
    return this.currentServiceType === type;
  }

  isRequestActive(type: string): boolean {
    return this.currentServiceType === type;
  }

  logout(): void {
    this.securityService.logout().subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  onNodeEvent($event: { key: string; data: any }) {
    if ($event.key == 'click') {
      if ($event.data == 'logout') {
        this.logout();
      }
    }
  }
}
