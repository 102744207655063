<div [class.collapsed]="menuCollapsed" class="menu-container">
  <div class="menu">
    <div class="toggle-button" (click)="menuCollapsed = !menuCollapsed">
      <mat-icon svgIcon="collapse-data" [class.rotated]="menuCollapsed" aria-hidden="false"></mat-icon>
    </div>
    <div class="menu-items">
      <app-menu-node
        *ngFor="let m of menu"
        [data]="m"
        [isCollapsed]="menuCollapsed"
        (events)="onNodeEvent($event)"
      ></app-menu-node>
    </div>
  </div>
</div>
