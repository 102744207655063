import {environment} from "@env";
import {HttpClient} from "@angular/common/http";
import {RestResponseWrapper, unwrapResponse} from "@api/rest-client.service";
import {map} from "rxjs/operators";
import {debounce, interval, Observable, of} from "rxjs";
import {Autocomplete, AutoOutput} from "@gen/common/Autocomplete";

export abstract class BaseAutocompleteService {
  private baseUrl = environment.apiUrl + '/autocomplete';

  protected constructor(protected http: HttpClient) {
  }

  protected factory(path: string, prepareFn: (el: AutoOutput) => AutoOutput): Autocomplete {
    if (!path.startsWith("/")) {
      path = "/" + path
    }
    let auto = new Autocomplete();
    auto.input.subscribe(dto => {
      this.send(path, dto)
          .pipe(map(arr => arr.map(prepareFn)))
          .subscribe(data => {
            auto.out.next(data);
          });
    });

    auto.fetch = (incompleteDTO: AutoOutput) => {
      if (!Object.hasOwn(incompleteDTO, "value")) {
        //its a raw object from backend
        return of(prepareFn(incompleteDTO))
      }
      if (!incompleteDTO.value) {
        return this.http
            .get<RestResponseWrapper<AutoOutput>>(this.baseUrl + path + '/' + incompleteDTO.id)
            .pipe(map(unwrapResponse))
            .pipe(map(prepareFn))
      } else {
        return of(prepareFn(incompleteDTO))
      }
    };

    return auto;
  }

  private send(path: string, dto: any): Observable<AutoOutput[]> {
    return this.http
        .get<RestResponseWrapper<AutoOutput[]>>(this.baseUrl + path, {params: dto})
        .pipe(map(unwrapResponse));
  }
}
