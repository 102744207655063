import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { roles } from '../common/role-hierarchy';
import { SecurityApi } from '@api/security-api';
import { UserInfoDTO } from '@dto/user-info-dto';
import { AnonymousUserInfoCache, UserInfoCache, UserInfoCache2 } from '@cms/services/UserInfoCache';
import { HttpClient } from '@angular/common/http';
import { UserTokenService } from '@cms/services/user-token.service';
import { isPlatformServer } from '@angular/common';

export let anonymousUser = {
  id: 0,
  name: '',
  role: 'ROLE_ANONYMOUS',
};

@Injectable({
  providedIn: 'root',
  deps: [HttpClient, SecurityApi],
})
export class SecurityService {
  private roles = roles;

  private cache!: AnonymousUserInfoCache;
  updateUserSubject: BehaviorSubject<Partial<UserInfoDTO>>;
  updateRoleSubject: Observable<string>;

  constructor(
    private securityApi: SecurityApi,
    private userTokenService: UserTokenService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.updateUserSubject = new BehaviorSubject<Partial<UserInfoDTO>>(anonymousUser);

    this.updateRoleSubject = this.updateUserSubject.pipe(
      tap(el => {}),
      map(user => user.role!!)
    );

    if (isPlatformServer(this.platformId)) {
      this.cache = new AnonymousUserInfoCache(() => this.securityApi.me());
    } else {
      this.cache = new UserInfoCache2(() => this.securityApi.me());
    }
  }

  login(email: string, password: string): Observable<UserInfoDTO> {
    return this.securityApi.login(email.trim(), password).pipe(
      tap(userInfo => {
        this.cache.setMe(userInfo);
      })
    );
  }

  logout() {
    return this.securityApi.logout().pipe(
      tap(_ => {
        this.userTokenService.clearToken();
        this.cache.setMe(anonymousUser);
      })
    );
  }

  public inRole(role: string): Observable<boolean> {
    return this.cache.getMe().pipe(
      map(me => {
        //@ts-ignore
        let grants = this.roles[me.role];
        return grants && grants.some((r: string) => r == role);
      })
    );
  }

  protected getReqPath(): string {
    return 'security';
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<UserInfoDTO> {
    return this.securityApi.changePassword('', oldPassword, newPassword);
  }

  public restorePasswordFirstStep(email: string): Observable<any> {
    return this.securityApi.forgetPass(email);
  }

  public restorePasswordFinalStep(key: string, newPass: string): Observable<any> {
    return this.securityApi.finishForgetPass(key, newPass);
  }
}
