import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function equalsPasswordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // @ts-ignore
    let pass1 = control.get('password').value;
    // @ts-ignore
    let pass2 = control.get('password2').value;
    if (pass1 && pass2) {
      if (!pass1.length || !pass2.length) {
        return null;
      }

      let isEquals = pass1 === pass2;
      let isLengthValid = pass1.length >= 7 && pass2.length >= 7;

      if (!isEquals) {
        return { passNotEquals: { hasError: true, field: 'password2' } };
      } else if (!isLengthValid) {
        return { minlength: { requiredLength: 7, actualLength: pass1.length } };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
}
