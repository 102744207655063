import { FormGroup } from '@angular/forms';

export function processFormErrors(errors: any, form: FormGroup, remap?: any) {
  if (errors.code == 'ValidationException') {
    errors.data.forEach((el: any) => {
      let field = form.controls[el.field];
      if (!field) return;
      let errors = Object.assign({}, field.errors);
      if (remap && remap[el.code]) {
        errors[remap[el.code]] = true;
      } else {
        errors[el.code] = true;
      }
      field.setErrors(errors);
    });
  }
}

export const emailRegexp = /^[a-z0-9\-_.]+@[a-z0-9\-_.]+\.[a-z0-9\-_]{2,5}$/i;
