import {Autocomplete, AutoOutput} from "@gen/common/Autocomplete"
// @ts-ignore
import {parseDate} from "./base-entity"


export class LkCompanyContactsDTO  implements AutoOutput {
     id?:number
     contactName?:string
     contactPhone?:string
     contactEmail?:string


    get value() { return this.contactName!!};


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem






        return this
    }

}

