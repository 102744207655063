import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { getMeta, MetaDTO } from '../common/seo';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private meta: Meta,
    private title: Title
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log('Navigation End', event.url, event.urlAfterRedirects);
        this.setMeta(getMeta(event.url), event.url);
        this.meta.removeTag('name=viewport');
        if (event.url.startsWith('/lk')) {
          this.meta.addTag({
            name: 'viewport',
            content: 'width=1240, initial-scale=1.0',
          });
        } else {
          this.meta.addTag({
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          });
        }
      }
    });
  }

  setMeta(meta: MetaDTO, url: string) {
    if (!meta) return;

    this.meta.removeTag('name=title');
    this.meta.removeTag('name=description');
    this.meta.removeTag('itemprop=image');
    this.meta.removeTag("property='og:title'");
    this.meta.removeTag("property='og:image'");
    this.meta.removeTag("property='og:description'");
    this.meta.removeTag("property='og:type'");

    this.title.setTitle('');

    if (meta) {
      this.title.setTitle(meta.title);
      this.meta.addTags([
        { name: 'title', content: meta.title },
        { name: 'description', content: meta.desc },

        { property: 'og:title', content: meta.title },
        {
          property: 'og:image',
          // @ts-ignore
          content: meta.img || `${environment.backend}/assets/images/ogimage.png`,
        },
        { property: 'og:description', content: meta.desc },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'ISZ' },
        { property: 'og:url', content: `${environment.backend}${url}` },
        {
          itemprop: 'image',
          // @ts-ignore
          content: meta.img || `${environment.backend}/assets/images/ogimage.png`,
        },
      ]);
    }
  }
}
