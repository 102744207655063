import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AboutComponent } from './pages/about/about.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FaqDetailComponent } from './pages/faq-detail/faq-detail.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RoleGuard } from '@cms/role-guard.service';
import { RestorePasswordComponent } from './pages/restore-password/restore-password.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'requests', loadComponent: () => import('./pages/requests-table/requests-table.component') },
  { path: 'about', component: AboutComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'faq/:id', component: FaqDetailComponent },
  { path: 'subscription', loadComponent: () => import('./pages/subscription/subscription.component') },
  { path: 'contacts', component: ContactsComponent },
  { path: 'login', redirectTo: '/', pathMatch: 'full' },
  { path: 'terms', loadComponent: () => import('./pages/terms/terms.component') },
  { path: 'private-info', loadComponent: () => import('./pages/private-info/private-info.component') },
  { path: 'privacy-policy', loadComponent: () => import('./pages/privacy-policy/privacy-policy.component') },
  {
    path: 'services-agreement',
    loadComponent: () => import('./pages/services-agreement/services-agreement.component'),
  },
  { path: 'restore-password/:key', component: RestorePasswordComponent },
  {
    path: 'company',
    canActivate: [() => RoleGuard('ROLE_USER')],
    loadComponent: () => import('./pages/public-company-info/public-company-info.component'),
  },
  {
    path: 'lk',
    loadChildren: () => import('./lk/route'),
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
