import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgIf } from '@angular/common';
import { MarkdownModule } from 'ngx-markdown';

interface Faq {
  id: string;
  question: string;
  content: string;
}

@Component({
  selector: 'app-faq-detail',
  templateUrl: './faq-detail.component.html',
  styleUrls: ['./faq-detail.component.css'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgIf, MarkdownModule],
})
export class FaqDetailComponent implements OnInit {
  faq: Faq | undefined;

  faqs: Faq[] = [
    {
      id: 'chto-takoe-isz',
      question: 'Что такое ISZ?',
      content: `
        # Что такое ISZ?
        На этом сервисе компании публикуют свои услуги в сфере контейнерных железнодорожных грузоперевозок. Здесь же пользователи могут найти подходящее предложение, связаться с компанией и организовать грузоперевозку.

        Наш сервис позволит вам быстро и удобно найти актуальное предложение на рынке логистики в считанные клики.
      `,
    },
    {
      id: 'kak-zaregistrirovatsya',
      question: 'Как зарегистрироваться',
      content: `
        # Как зарегистрироваться
        Регистрация доступна всем пользователям в шапке сайта.

        **Шаг 1.**
        Нажмите кнопку “Вход” в верхнем правом углу.
        ![Регистрация](assets/images/faq/faq2_1.png)

        **Шаг 2.**
        Заполните соответствующие поля “E-mail” и “Пароль”. Обязательно подтвердите пароль, убедитесь, что он совпадает с указанным.

        ![Регистрация](assets/images/faq/faq2_2.png)
      `,
    },
    {
      id: 'kak-zapolnit-dannye-kompanii',
      question: 'Как заполнить данные компании',
      content: `
        # Как заполнить данные компании
        После регистрации на сайте Вам становится доступен Личный кабинет, где вы можете заполнить информацию о компании в разделе Данные компании.

        **Шаг 1.**
        Чтобы добавить данные, введите в строке поиска наименование, юридический адрес, ОГРН или ИНН компании.
        ![Как заполнить данные компании](assets/images/faq/faq3_1.png)

        **Шаг 2.**
        Убедитесь, что данные указаны верно и введите данные лица-представителя, с которым наша поддержка может связаться для оперативного решения вопросов.
        ![Как заполнить данные компании](assets/images/faq/faq3_2.png)
      `,
    },
    {
      id: 'kak-sozdat-uslugu',
      question: 'Как создать услугу',
      content: `
        # Как создать услугу
        **Шаг 1.**
        Перейдите в раздел “Ваши услуги” Личного кабинета и создайте услугу с помощью кнопки в верхнем правом углу.
        ![Как создать услугу](assets/images/faq/faq4_1.png)

        **Шаг 2.**
        Заполните параметры услуги: Тип услуги, тип и размер контейнера, ставку, пункт отправления и пункт назначения. Нажмите кнопку “Продолжить”.
        ![Как создать услугу](assets/images/faq/faq4_2.png)

        **Шаг 3.**
        Прикрепите менеджера. Если список менеджеров пуст, создайте нового. Если менеджер существует, выберите нужного из списка.
        ![Как создать услугу](assets/images/faq/faq4_3.png)

        **Шаг 4.**
        Опубликуйте услугу с помощью одноименной кнопки. Сохраните введенные данные.
        ![Как создать услугу](assets/images/faq/faq4_4.png)
      `,
    },
    {
      id: 'kak-sozdat-sotrudnika',
      question: 'Как создать сотрудника',
      content: `
        # Как создать сотрудника
        Чтобы услуга была опубликована, необходимо закрепить за ней сотрудника - человека, который будет принимать обращения по телефону и электронной почте.

        **Шаг 1.**
        Для создания сотрудника перейдите в раздел Личного кабинета “Контакты сотрудников”. В верхнем правом углу нажмите кнопку добавления, после чего перед Вами откроется форма заполнения данных.
        ![Как создать сотрудника](assets/images/faq/faq5_1.png)

        **Шаг 2.**
        Укажите ФИО, телефон и адрес электронной почты сотрудника. Сохраните данные.
        ![Как создать сотрудника](assets/images/faq/faq5_2.png)
      `,
    },
    {
      id: 'kak-naznachit-sotrudnika',
      question: 'Как назначить сотрудника',
      content: `
        # Как назначить сотрудника
        Вы можете выбрать ранее созданного сотрудника из выпадающего списка при создании услуги.
        ![Как назначить сотрудника](assets/images/faq/faq6_1.png)

        Также вы можете создать нового сотрудника на этапе заполнения данных услуги. Для этого нажмите кнопку добавления на этапе Ваш сотрудник. Заполните и сохраните контактную информацию.
        ![Как назначить сотрудника](assets/images/faq/faq6_2.png)
      `,
    },
    {
      id: 'kak-otvyazat-sotrudnika-ot-uslugi',
      question: 'Как отвязать сотрудника от услуги',
      content: `
        # Как отвязать сотрудника от услуги
        Все услуги закреплены за сотрудником. Чтобы отвязать сотрудника от услуги удалите его или назначьте нового.

        Для удаления сотрудника войдите в раздел “Ваши сотрудники” Личного кабинета. Кликните по нужному сотруднику, после этого откроется карточка сотрудника. Внизу нажмите на кнопку “Удалить”.

        Обратите внимание, что при удалении менеджера услуга становится неопубликованной.
      `,
    },
    {
      id: 'kak-otkryt-kontakty-kompanii',
      question: 'Как открыть контакты компании',
      content: `
        # Как открыть контакты компании
        Чтобы увидеть контакты компании в интересующих услугах, необходимо быть авторизованным пользователем с активированной подпиской.
      `,
    },
    {
      id: 'kak-aktivirovat-podpisku',
      question: 'Как активировать подписку',
      content: `
        # Как активировать подписку
        **Шаг 1.** Для активации подписки войдите на сайт и перейдите в раздел Подписка либо войдите в раздел “Ваша подписка” Личного кабинета.
        ![Как активировать подписку](assets/images/faq/faq8_1.png)

        **Шаг 2.** Выберите удобный тариф и нажмите кнопку “Купить”, после чего сформируется счёт на оплату. Данный счёт можно найти в Личном кабинете в разделе “Выставленные счета”.
        ![Как активировать подписку](assets/images/faq/faq8_2.png)

        После оплаты счета статус изменится на “Оплачен”, и контактная информация заявок будет открыта для Вас в течение срока подписки.
      `,
    },
    {
      id: 'kak-oplatit-schet',
      question: 'Как оплатить счет',
      content: `
        # Как оплатить счет
        **Шаг 1.** Выберите тариф Подписки в Личном кабинете или в разделе "Подписка"

        **Шаг 2.** Сервис сформирует счет, который придет Вам на почту и отразится в разделе "Выставленные счета" Личного кабинета. Скачайте счет, чтобы ознакомиться с деталями оплаты.
        ![Счет](assets/images/faq/faq9_1.png)

        **Шаг 3.** Оплатите счет по указанным реквизитам в теле документа. Обратите внимание, что срок оплаты ограничен 14 календарными днями. Если срок истек, необходимо сформировать новый счет.

        **Шаг 4.** После оплаты счета статус изменится на "Оплачен", а Подписка активируется на выбранный Вами период.

        После оплаты станет доступен Акт выполненных работ, который также можно скачать в формате PDF.
      `,
    },
    {
      id: 'ne-udaetsya-vojti-v-lichnyj-kabinet',
      question: 'Не удается войти в Личный кабинет',
      content: `
        # Не удается войти в Личный кабинет
        Если Ваш пароль неверен, воспользуйтесь функцией восстановления пароля на странице авторизации.

        **Шаг 1.** Перейдите по ссылке Восстановление пароля.
        ![Не удается войти в Личный кабинет](assets/images/faq/faq10_1.png)

        **Шаг 2.** Укажите адрес электронной почты, который был указан при регистрации.
        ![Не удается войти в Личный кабинет](assets/images/faq/faq10_2.png)

        **Шаг 3.** На указанный адрес Вам придет письмо с ссылкой на создание нового пароля.

        **Шаг 4.** Задайте новый пароль от Личного кабинета.
        ![Не удается войти в Личный кабинет](assets/images/faq/faq10_3.png)
      `,
    },
    {
      id: 'oplachen-schet-no-podpisku-ne-aktivirovali',
      question: 'Оплачен счёт, но подписка не активирована',
      content: `
        # Оплачен счёт, но подписка не активирована
        В случае не активации подписки позвоните или напишите нам:

        **Телефон:** +7 (963) 642-48-93

        **Электронная почта:** ilya.sidorov@isz.su
      `,
    },
  ];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.faq = this.faqs.find(f => f.id === id);
  }
}
