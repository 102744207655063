export enum SearchCondition {
            EQ, 
            NOT_EQ, 
            BETWEEN, 
            IS_TRUE, 
            IS_FALSE, 
            LIKE, 
            ILIKE, 
            GE, 
            GTE, 
            LE, 
            LTE, 
            IN
}