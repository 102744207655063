import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from './services/security.service';

export const RoleGuard = (
  role: string
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const securityService = inject(SecurityService);
  const router = inject(Router);
  return securityService.inRole(role).then(inRole => (inRole ? true : router.parseUrl('/login')));
};
