import {Injectable} from '@angular/core'
import {HttpClient, HttpErrorResponse} from "@angular/common/http"
import {Observable, throwError} from "rxjs"
import {catchError} from "rxjs/operators"
import {map} from "rxjs/operators"

import {RegistrationDTO} from "../dto/registration-dto"
import {ChangePasswordDTO} from "../dto/change-password-dto"
import {UserInfoDTO} from "../dto/user-info-dto"
import {LoginDTO} from "../dto/login-dto"
import {ForgetPassDTO} from "../dto/forget-pass-dto"

import {Page, RestClientService, RestResponseWrapper, unwrapResponse} from "./rest-client.service"

@Injectable({ providedIn: 'root'
})
export class SecurityApi extends RestClientService<UserInfoDTO> {
    constructor(httpClient: HttpClient) {
        super(httpClient)
    }

    protected getReqPath (): string {
        return "security"
    }

    me(): Observable<UserInfoDTO> {
        return this.httpClient.get<RestResponseWrapper<UserInfoDTO>>(this.apiUrl + "/me")
            .pipe(map(unwrapResponse),
                map(el=>{
                return this.getGenericType().prototype.prepare.apply(el)
            }))
    }


    login(email: string, password: string): Observable<UserInfoDTO> {
        let loginDTO = new LoginDTO()
        loginDTO.email = email
        loginDTO.password = password

        return this.httpClient.post<RestResponseWrapper<UserInfoDTO>>(this.apiUrl + "/login", loginDTO)
            .pipe(map(unwrapResponse),
                map(el => {
                    return this.getGenericType().prototype.prepare.apply(el)
                }))
    }

    logout(unused ?: undefined) {
        return this.httpClient.post<undefined>(this.apiUrl + "/logout", {})
    }

        registration(email: string, password: string): Observable<UserInfoDTO> {
        let loginDTO = new LoginDTO()
        loginDTO.password = password
        loginDTO.email = email.toLowerCase()
        return this.httpClient.post<RestResponseWrapper<UserInfoDTO>>(this.apiUrl + "/registration", loginDTO)
            .pipe(map(unwrapResponse),
                map(el=> {
                    return this.getGenericType().prototype.prepare.apply(el)
                }))
    }

    forgetPass(email: string): Observable<any> {
        let dto = new ForgetPassDTO()
        dto.email = email
        return this.httpClient.post(this.apiUrl + "/forget-pass", dto)
            // @ts-ignore
            .pipe(map(unwrapResponse))
    }

    finishForgetPass(key: string, password:string): Observable<any> {
        let dto = new ChangePasswordDTO()
        dto.email = "any"
        dto.newPassword = password
        dto.password = password
        return this.httpClient.post(this.apiUrl + "/forget-pass/"+key, dto)
            // @ts-ignore
            .pipe(map(unwrapResponse))
    }

    changePassword(email:string, oldPass:string, newPass:string): Observable<UserInfoDTO>{
        let dto = new ChangePasswordDTO()
        dto.email = email
        dto.password = oldPass
        dto.newPassword = newPass
        return this.httpClient.post<RestResponseWrapper<UserInfoDTO>>(this.apiUrl + "/change-password", dto)
            .pipe(map(unwrapResponse))
    }
    /**
     * @deprecated method disabled at this api
     *
     */
    override getAll (searchcriteria ?: any): Observable<Page<UserInfoDTO>> {
        throw "Method disabled"
    }

    /**
     * @deprecated method disabled at this api
     *
     */
    override getOne (searchcriteria:any): Observable<UserInfoDTO> {
        throw "Method disabled"
    }


    /**
     * @deprecated method disabled at this api
     *
     */
    override save (entity: UserInfoDTO): Observable<UserInfoDTO> {
        throw "Method disabled"
    }

    protected getGenericType (): any {
        return UserInfoDTO
    }
}
