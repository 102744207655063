export class BaseEntity {
    id: number = -1
    // @ts-ignore
    createdAt: Date
    // @ts-ignore
    updatedAt: Date

    public prepare()
    {
        this.createdAt = this.createdAt && parseDate(this.createdAt)
        this.updatedAt = this.updatedAt && parseDate(this.updatedAt)
    }
}

export function parseDate(
val : Date): Date
{
    let arr =
    val as unknown as Array<number>
    let i = 0
    if (arr.length == 3) {
        return new Date (arr[i++], arr[i++]-1, arr[i++])
    } else {
        return new Date (arr[i++], arr[i++]-1, arr[i++], arr[i++], arr[i++], arr[i++])
    }
}