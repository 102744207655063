import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-company-info-link',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule, RouterLink],
  templateUrl: './company-info-link.component.html',
  styleUrls: ['./company-info-link.component.css'],
})
export class CompanyInfoLinkComponent {
  @Input()
  companyId?: number;
}
