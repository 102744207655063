<app-routs-search-form [searchFilter]="filters" (onSearchFilter)="doSearch($event)"></app-routs-search-form>

<!-- Table -->
<div class="table-container">
  <div class="striped-table-container">
    <!-- Cards -->
    <div class="cards-container">
      <ng-container *ngIf="services.length; else noServices">
        <ng-container *ngFor="let service of services">
          <app-routs-card [service]="service"></app-routs-card>
        </ng-container>
      </ng-container>
    </div>

    <ng-template #noServices>
      <app-empty-search></app-empty-search>
    </ng-template>

    <mat-paginator
      class="mt-2"
      [length]="length"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions"
      [hidePageSize]="hidePageSize"
      (page)="onPaginatorEvent($event)"
      aria-label="Выберите страницу"
      *ngIf="dataSource.data.length != 0"
    >
    </mat-paginator>
  </div>
</div>
