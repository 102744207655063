import {BaseEntity} from "./base-entity"
import {Autocomplete, AutoOutput} from "@gen/common/Autocomplete"
// @ts-ignore
import {parseDate} from "./base-entity"


export class Stations extends BaseEntity implements AutoOutput {
     name?: string
  kladr?: string


    get value() { return this.name!!};


    // @ts-ignore
    public prepare()
    {//may be empty, it is not a problem



            BaseEntity.prototype.prepare.apply(this)



        return this
    }

}

