import { Component } from '@angular/core';
import { RoutsTableComponent } from '../pages/routs-table/routs-table.component';
import { SearchPageHeaderComponent } from '@common/search-page-header/search-page-header.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  standalone: true,
  imports: [RoutsTableComponent, RoutsTableComponent, SearchPageHeaderComponent],
})
export class MainComponent {}
