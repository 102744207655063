<div class="form-container">
  <!-- Login Form Content -->
  <form
    class="w-75"
    [formGroup]="loginForm"
    (submit)="doLogin()"
  >
    <div class="input-with-icon">
      <mat-icon matPrefix class="input-icon">person_outline</mat-icon>
      <mat-form-field appearance="fill" class="with-icon">
        <mat-label>Имя пользователя</mat-label>
        <input [formControl]="loginForm.username" matInput />
        <mat-error *ngIf="loginForm.username.error('required')">Обязательное поле</mat-error>
        <mat-error *ngIf="loginForm.username.error('pattern')">Неправильный email</mat-error>
        <mat-error *ngIf="loginForm.username.error('NotExistsException')"
        >Пользователь не зарегистрирован
        </mat-error>
      </mat-form-field>
    </div>

    <div class="input-with-icon">
      <mat-icon matPrefix class="input-icon">lock_outline</mat-icon>
      <mat-form-field appearance="fill" class="with-icon">
        <mat-label>Пароль</mat-label>
        <input [formControl]="loginForm.password" matInput type="password" />
        <mat-error *ngIf="loginForm.password.error('required')">Обязательное поле</mat-error>
        <mat-error *ngIf="loginForm.password.error('AuthException')">Не правильный пароль</mat-error>
      </mat-form-field>
    </div>

    <div class="button-container">
      <button mat-raised-button style="padding-left: 40px; padding-right: 40px" color="primary">
        Войти
      </button>
    </div>
  </form>

  <div class="d-flex justify-content-center">
    <button (click)="openRestorePasswordForm.emit()" mat-button color="primary">Забыли пароль?</button>
  </div>

</div>
