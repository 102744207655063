<div class="container">
  <h1>Контакты</h1>
  <main class="d-flex flex-column flex-lg-row-reverse">
    <div class="flex-lg-column">
      <div class="tile tile_card">
        <div class="icon-container icon-container__green">
          <mat-icon>email</mat-icon>
        </div>
        <div class="text-container">
          <h3>Напишите нам</h3>
          <a href="mailto: info@isz.su">info&#64;isz.su</a>
        </div>
      </div>

<!--      <div class="tile tile_card mt-1">
        <div class="icon-container icon__green">
          <mat-icon>phone</mat-icon>
        </div>
        <div class="text-container">
          <h3>Свяжитесь с нами</h3>
          <p>+7(925) 146-76-14</p>
        </div>
      </div>-->

      <div class="tile tile_card mt-1">
        <div class="icon-container icon__green">
          <mat-icon>place</mat-icon>
        </div>
        <div class="text-container">
          <h3>Наши контакты</h3>
          <p>Южная улица, 8, Щербинка, Москва</p>
        </div>
      </div>
    </div>

    <div class="col me-lg-2 mt-sm-2">
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A99b12572f889973680c09e664bcb0cfe4ec8903ec9b3a9cad8db853eb6a90ae2&amp;source=constructor"
        width="100%"
        height="350"
        frameborder="0"
      ></iframe>
    </div>


  </main>
</div>
