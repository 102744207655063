import { MenuNodeData } from './menu-node/menu-node.component';
import { ServiceType } from '@dto/service-type';
import { RoleGuard } from '@cms/role-guard.service';
import { inject, InjectionToken } from '@angular/core';
import { SecurityService } from '@cms/services/security.service';

export const LK_MENU_DATA_INJECTION_TOKEN = new InjectionToken<MenuNodeData[]>('lkMenuDataInjectionToken', {
  providedIn: 'root',
  factory: () => {
    return Menu.map(el => {
      if (el.canShow) {
        // @ts-ignore
        el.canShow = el.canShow();
      }
      return el;
    });
  },
});

export let Menu: MenuNodeData[] = [
  {
    text: 'Данные компании',
    icon: 'company-data',
    link: ['./company-data'],
  },
  {
    text: 'Контакты сотрудников',
    icon: 'employee-contacts',
    link: ['./employee-contacts'],
  },
  {
    text: 'Ваша подписка',
    icon: 'your-subscription',
    link: ['./your-subscription'],
  },
  {
    text: 'Ваши услуги',
    icon: 'your-services',
    link: ['./your-services/'],
    subs: [
      {
        text: 'Автодоставка',
        icon: '',
        link: ['./your-services/'],
        queryParams: {
          serviceType: ServiceType[ServiceType.AUTO],
        },
      },
      {
        text: 'Терминал на прием',
        icon: '',
        link: ['./your-services/'],
        queryParams: {
          serviceType: ServiceType[ServiceType.TERMINAL_RX],
        },
      },
      {
        text: 'Терминал на отправку',
        icon: '',
        link: ['./your-services/'],
        queryParams: {
          serviceType: ServiceType[ServiceType.TERMINAL_TX],
        },
      },
      {
        text: 'Авто + Терминал',
        icon: '',
        link: ['./your-services/'],
        queryParams: {
          serviceType: ServiceType[ServiceType.AUTO_TERMINAL],
        },
      },
      {
        text: 'Аренда контейнеров',
        icon: '',
        link: ['./your-services/'],
        queryParams: {
          serviceType: ServiceType[ServiceType.CONTAINER_RENT],
        },
      },
    ],
  },
  {
    text: 'Ваши заявки',
    icon: 'your-services',
    link: ['./your-requests/'],
    subs: [
      {
        text: 'Автодоставка',
        icon: '',
        link: ['./your-requests/'],
        queryParams: {
          serviceType: ServiceType[ServiceType.AUTO],
        },
      },
      {
        text: 'Терминал на прием',
        icon: '',
        link: ['./your-requests'],
        queryParams: {
          serviceType: ServiceType[ServiceType.TERMINAL_RX],
        },
      },
      {
        text: 'Терминал на отправку',
        icon: '',
        link: ['./your-requests/'],
        queryParams: {
          serviceType: ServiceType[ServiceType.TERMINAL_TX],
        },
      },
      {
        text: 'Авто + Терминал',
        icon: '',
        link: ['./your-requests/'],
        queryParams: {
          serviceType: ServiceType[ServiceType.AUTO_TERMINAL],
        },
      },
      {
        text: 'Аренда контейнеров',
        icon: '',
        link: ['./your-requests/'],
        queryParams: {
          serviceType: ServiceType[ServiceType.CONTAINER_RENT],
        },
      },
    ],
  },
  {
    text: 'Выставленные счета',
    icon: 'issued-invoices',
    link: ['./issued-invoices'],
  },
  {
    text: 'Админика',
    icon: 'admin-panel-settings',
    canShow: () => {
      let service = inject(SecurityService);
      return () => service.inRole('ROLE_ADMIN');
    },
    subs: [
      {
        text: 'Счета',
        icon: '',
        link: ['./admin/bills'],
      },
      {
        text: 'Подписка',
        icon: '',
        link: ['./admin/subscriptions'],
      },
    ],
  },
  {
    text: 'Сменить пароль',
    icon: 'change-password',
    link: ['./change-password'],
  },
  {
    text: 'Выйти',
    icon: 'logout',
    events: { click: 'logout' },
  },
];
