import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { ServiceTypeTabsComponent } from './service-type-tabs/service-type-tabs.component';
import { ServiceType } from '@dto/service-type';
import { SearchFilters } from './search-filters';
import { AutoOutput } from '@gen/common/Autocomplete';
import { SelectedContainerFilter } from '@common/container-type-select/selected-container-filter';
import { ContainerTypeSelectComponent } from '@common/container-type-select/container-type-select.component';
import { RouteAutocompleteComponent } from '@common/route-autocomplete/route-autocomplete.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Stations } from '@dto/stations';
import { ServiceRoutsFormComponent } from '@common/routs-search-form/service-routs-form/service-routs-form.component';

@Component({
  selector: 'app-routs-search-form',
  standalone: true,
  imports: [
    CommonModule,
    ServiceTypeTabsComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    RouteAutocompleteComponent,
    ContainerTypeSelectComponent,
    ServiceRoutsFormComponent,
  ],
  templateUrl: './routs-search-form.component.html',
  styleUrls: ['./routs-search-form.component.css'],
})
export class RoutsSearchFormComponent implements OnInit {
  isMobile: boolean = false;

  @Input()
  searchFilter!: SearchFilters;

  @Output()
  onSearchFilter = new EventEmitter<SearchFilters>();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {}

  applyFilter() {
    this.onSearchFilter.emit(this.searchFilter);
  }

  selectServiceType(serviceType: ServiceType | undefined) {
    this.searchFilter.serviceType = serviceType;
    this.applyFilter();
  }
}
