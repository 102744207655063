<ng-container *ngIf="canViewSignal()">
  <div
    *ngIf="data.events || !data.link; else routeTemplate"
    [class.collapsed]="isCollapsed"
    class="nav-link"
    (click)="nodeClicked()"
  >
    <mat-icon *ngIf="data.icon" svgIcon="{{data.icon}}" aria-hidden="false"></mat-icon>
    <span class="text">{{data.text}}</span>
  </div>
  <ng-template #routeTemplate>
    <a
      [class.collapsed]="isCollapsed"
      class="nav-link"
      routerLink="{{data.link}}"
      routerLinkActive="active"
      [queryParams]="data.queryParams"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="nodeClicked()"
    >
      <mat-icon *ngIf="data.icon" svgIcon="{{data.icon}}" aria-hidden="false"></mat-icon>
      <span class="text">{{data.text}}</span>
    </a>
  </ng-template>

  <div *ngIf="data && data.subs && !isCollapsed" class="submenu" [class.collapsed]="isSubMenuCollapsed">
    <app-menu-node
      *ngFor="let s of data.subs"
      [data]="s"
    ></app-menu-node>
  </div>
</ng-container>



