import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {Page, RestClientService, RestResponseWrapper, unwrapResponse} from "./rest-client.service";

import {LkSelectTariffDTO} from "../dto/lk-select-tariff-dto";



@Injectable({
    providedIn: 'root'
})
export class LkSelectTariffDTOApi extends RestClientService<LkSelectTariffDTO> {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected getReqPath(): string {
        return "lk-select-tariff-dto";
    }

    protected getGenericType(): any {
        return LkSelectTariffDTO;
    }

}