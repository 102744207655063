<div class="form-container">
  <!-- Registration Form Content -->
  <form [formGroup]="registrationForm" (submit)="doRegister()">
    <div class="input-with-icon">
      <mat-icon matPrefix class="input-icon">email</mat-icon>
      <mat-form-field appearance="fill" class="with-icon">
        <mat-label>Почта</mat-label>
        <input [formControl]="registrationForm.username" matInput type="email" />
        <mat-error *ngIf="registrationForm.username.error('required')">Обязательное поле</mat-error>
        <mat-error *ngIf="registrationForm.username.error('pattern')">Неправильный email</mat-error>
      </mat-form-field>
    </div>

    <div class="input-with-icon">
      <mat-icon matPrefix class="input-icon">lock_outline</mat-icon>
      <mat-form-field appearance="fill" class="with-icon">
        <mat-label>Пароль</mat-label>
        <input
          [formControl]="registrationForm.password"
          [errorStateMatcher]="passErrorStateMatcher"
          matInput
          type="password"
        />
        <mat-error *ngIf="registrationForm.password.hasError('required')">Обязательное поле</mat-error>
        <mat-error *ngIf="registrationForm.password.hasError('minlength')"
        >Пароль должен быть не менее 7 символов</mat-error
        >
      </mat-form-field>
    </div>

    <div class="input-with-icon">
      <mat-icon matPrefix class="input-icon">lock_outline</mat-icon>
      <mat-form-field appearance="fill" class="with-icon">
        <mat-label>Подтверждение пароля</mat-label>
        <input
          [formControl]="registrationForm.password2"
          [errorStateMatcher]="passErrorStateMatcher"
          matInput
          type="password"
        />
        <mat-error *ngIf="registrationForm.password2.hasError('required')">Обязательное поле</mat-error>
        <mat-error *ngIf="registrationForm.password2.hasError('minlength')"
        >Пароль должен быть не менее 7 символов
        </mat-error>
        <mat-error *ngIf="registrationForm.error('passNotEquals')">Пароли должны совпадать</mat-error>
      </mat-form-field>
    </div>

    <mat-checkbox class="terms-checkbox" [formControl]="registrationForm.checkBox">
      Согласен на обработку персональных данных <br />
      <a routerLink="/privacy-policy">Политика конфиденциальности </a>
    </mat-checkbox>

    <mat-error *ngIf="registrationForm.checkBox.error('required')"> Обязательное поле</mat-error>

    <div class="button-container">
      <button mat-raised-button color="primary">Регистрация</button>
    </div>
  </form>
</div>
