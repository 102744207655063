<div class="form__login-register">
  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="center"
    class="w-100"
    [ngClass]="{ 'hide-tabs': showResetPasswordSuccess }"
  >
    <mat-tab label="Вход">
      <div class="form-container">
        <!-- Login Form Content -->
        <form
          *ngIf="!showResetPasswordForm && !showResetPasswordSuccess"
          class="w-75"
          [formGroup]="loginForm"
          (submit)="doLogin()"
        >
          <div class="input-with-icon">
            <mat-icon matPrefix class="input-icon">person_outline</mat-icon>
            <mat-form-field appearance="fill" class="with-icon">
              <mat-label>Имя пользователя</mat-label>
              <input [formControl]="loginForm.username" matInput />
              <mat-error *ngIf="loginForm.username.error('required')">Обязательное поле</mat-error>
              <mat-error *ngIf="loginForm.username.error('pattern')">Неправильный email</mat-error>
              <mat-error *ngIf="loginForm.username.error('NotExistsException')"
                >Пользователь не зарегистрирован
              </mat-error>
            </mat-form-field>
          </div>

          <div class="input-with-icon">
            <mat-icon matPrefix class="input-icon">lock_outline</mat-icon>
            <mat-form-field appearance="fill" class="with-icon">
              <mat-label>Пароль</mat-label>
              <input [formControl]="loginForm.password" matInput type="password" />
              <mat-error *ngIf="loginForm.password.error('required')">Обязательное поле</mat-error>
              <mat-error *ngIf="loginForm.password.error('AuthException')">Не правильный пароль</mat-error>
            </mat-form-field>
          </div>

          <div class="button-container">
            <button mat-raised-button style="padding-left: 40px; padding-right: 40px" color="primary">
              Войти
            </button>
          </div>
          <mat-divider class="mt-3"></mat-divider>
          <div class="d-flex justify-content-center">
            <button (click)="toggleResetPasswordForm()" mat-button color="primary">Забыли пароль?</button>
          </div>
        </form>

        <!-- Reset Password Form -->
        <form
          *ngIf="showResetPasswordForm && !showResetPasswordSuccess"
          [formGroup]="resetPasswordForm"
          (submit)="doResetPassword()"
        >
          <mat-form-field appearance="fill">
            <mat-label>Введите ваш email</mat-label>
            <input formControlName="email" matInput type="email" />
            <mat-error *ngIf="resetPasswordForm.get('email')?.errors?.['required']">
              Обязательное поле
            </mat-error>
            <mat-error *ngIf="resetPasswordForm.get('email')?.errors?.['pattern']">
              Неправильный email
            </mat-error>
          </mat-form-field>
          <div class="button-container">
            <button mat-raised-button color="primary">Отправить</button>
          </div>
          <div class="back-to-login">
            <button (click)="toggleResetPasswordForm()" mat-button color="primary">
              Вернуться к авторизации
            </button>
          </div>
        </form>

        <!-- Success Message -->
        <form *ngIf="showResetPasswordSuccess" class="centered-message">
          <p>На вашу почту было отправлено письмо для восстановления пароля.</p>
          <button mat-raised-button color="primary" (click)="closeResetPasswordSuccess()">Продолжить</button>
        </form>
      </div>
    </mat-tab>
    <mat-tab label="Регистрация">
      <div class="form-container">
        <!-- Registration Form Content -->
        <form [formGroup]="registrationForm" (submit)="doRegister()">
          <div class="input-with-icon">
            <mat-icon matPrefix class="input-icon">email</mat-icon>
            <mat-form-field appearance="fill" class="with-icon">
              <mat-label>Почта</mat-label>
              <input [formControl]="registrationForm.username" matInput type="email" />
              <mat-error *ngIf="registrationForm.username.error('required')">Обязательное поле</mat-error>
              <mat-error *ngIf="registrationForm.username.error('pattern')">Неправильный email</mat-error>
            </mat-form-field>
          </div>

          <div class="input-with-icon">
            <mat-icon matPrefix class="input-icon">lock_outline</mat-icon>
            <mat-form-field appearance="fill" class="with-icon">
              <mat-label>Пароль</mat-label>
              <input
                [formControl]="registrationForm.password"
                [errorStateMatcher]="passErrorStateMatcher"
                matInput
                type="password"
              />
              <mat-error *ngIf="registrationForm.password.error('required')">Обязательное поле</mat-error>
              <mat-error *ngIf="registrationForm.password.hasError('minlength')"
                >Пароль должен быть не менее 7 символов</mat-error
              >
            </mat-form-field>
          </div>

          <div class="input-with-icon">
            <mat-icon matPrefix class="input-icon">lock_outline</mat-icon>
            <mat-form-field appearance="fill" class="with-icon">
              <mat-label>Подтверждение пароля</mat-label>
              <input
                [formControl]="registrationForm.password2"
                [errorStateMatcher]="passErrorStateMatcher"
                matInput
                type="password"
              />
              <mat-error *ngIf="registrationForm.password2.error('required')">Обязательное поле</mat-error>
              <mat-error *ngIf="registrationForm.password2.hasError('minlength')"
                >Пароль должен быть не менее 7 символов
              </mat-error>
              <mat-error *ngIf="registrationForm.error('passNotEquals')">Пароли должны совпадать</mat-error>
            </mat-form-field>
          </div>

          <mat-checkbox class="terms-checkbox" [formControl]="registrationForm.checkBox">
            Согласен на обработку персональных данных <br />
            <a routerLink="/privacy-policy">Политика конфиденциальности </a>
          </mat-checkbox>

          <mat-error *ngIf="registrationForm.checkBox.error('required')"> Обязательное поле</mat-error>

          <div class="button-container">
            <button mat-raised-button color="primary">Регистрация</button>
          </div>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
