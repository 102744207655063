import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerType } from '@dto/container-type';
import { PublicServiceView } from '../public-service.view';
import { MatDialog } from '@angular/material/dialog';
import { InRoleDirective } from '@cms/directives/InRoleDirective';
import { MatCardModule } from '@angular/material/card';
import { ContactsCellComponent } from '../../contacts-cell/contacts-cell.component';
import { ContaierSizeToCyrillicPipe } from '../../../pipes/contaier-size-to-cyrillic.pipe';
import { LoginRegisterComponent } from '../../../../login-register/login-register.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { CompanyInfoLinkComponent } from '@common/rout-card/desktop-card2/company-info-link/company-info-link.component';
import { ServiceTypeCyrillicComponent } from '@common/rout-card/desktop-card2/service-type-cirilic/service-type-cyrillic.component';

@Component({
  selector: 'app-desktop-card2',
  standalone: true,
  imports: [
    CommonModule,
    ContaierSizeToCyrillicPipe,
    InRoleDirective,
    MatCardModule,
    ContactsCellComponent,
    MatIconModule,
    MatTooltipModule,
    RouterLink,
    CompanyInfoLinkComponent,
    ServiceTypeCyrillicComponent,
  ],
  templateUrl: './desktop-card2.component.html',
  styleUrls: ['./desktop-card2.component.scss'],
})
export class DesktopCard2Component {
  ContainerTypes = ContainerType;

  @Input()
  view!: PublicServiceView;

  @Input()
  showCompanyLink: boolean = true;

  openLoginDialog(): void {
    this.dialog.open(LoginRegisterComponent, {
      width: '450px',
      position: { top: '25vh' },
    });
  }

  constructor(public dialog: MatDialog) {}
}
