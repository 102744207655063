import { ErrorStateMatcher } from '@angular/material/core';
import { AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';

export class ExternalStateMatcher implements ErrorStateMatcher {
  constructor(private getState: () => boolean) {}

  isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.getState();
  }
}
