<div class="form-container">
<form
  *ngIf="!showResetPasswordSuccess"
  [formGroup]="resetPasswordForm"
  (submit)="doResetPassword()"
>
  <mat-form-field appearance="fill">
    <mat-label>Введите ваш email</mat-label>
    <input formControlName="email" matInput type="email" />
    <mat-error *ngIf="resetPasswordForm.get('email')?.errors?.['required']">
      Обязательное поле
    </mat-error>
    <mat-error *ngIf="resetPasswordForm.get('email')?.errors?.['pattern']">
      Неправильный email
    </mat-error>
  </mat-form-field>
  <div class="button-container">
    <button mat-raised-button color="primary">Отправить</button>
  </div>
  <div class="back-to-login">
    <button (click)="hideRestorePasswordForm.emit()" mat-button color="primary">
      Вернуться к авторизации
    </button>
  </div>
</form>

<!-- Success Message -->
<form *ngIf="showResetPasswordSuccess" class="centered-message">
  <p>На вашу почту было отправлено письмо для восстановления пароля.</p>
  <button mat-raised-button color="primary" (click)="closeResetPasswordSuccess()">Продолжить</button>
</form>
</div>
