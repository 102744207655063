import { ServicePublicDTO } from '@dto/service-public-dto';
import { CompanyContacts } from '@dto/company-contacts';

export class PublicServiceView extends ServicePublicDTO {
  isFullCommentShown: boolean = false;

  toggleComment(): void {
    this.isFullCommentShown = !this.isFullCommentShown;
  }

  get mappedContacts(): CompanyContacts[] {
    return (
      this.contacts?.map(contact => ({
        id: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        contactName: contact.contactName ?? '',
        contactPhone: contact.contactPhone ?? '',
        contactEmail: contact.contactEmail ?? '',
        prepare: function (): CompanyContacts {
          return this as CompanyContacts;
        },
      })) ?? []
    );
  }

  get truncatedComment(): string {
    if (this.isFullCommentShown || !this.comment) {
      return this.comment ?? '';
    }
    return this.comment.length > 100 ? this.comment.substring(0, 100) + '...' : this.comment;
  }
}
