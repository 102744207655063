import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { ServiceType } from '@dto/service-type';
import { DomSanitizer } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ALL_ICON,
  CONTAINER_RENT_ICON,
  TERMINAL_IN_ICON,
  TERMINAL_OUT_ICON,
  TRUCK_ICON,
  TRUCK_PLUS_ICON,
} from '@common/routs-search-form/service-type-tabs/IconsData';

@Component({
  selector: 'app-service-type-tabs',
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule, MatButtonModule, MatIconModule, FormsModule],
  templateUrl: './service-type-tabs.component.html',
  styleUrls: ['./service-type-tabs.component.css'],
})
export class ServiceTypeTabsComponent {
  ServiceTypes = ServiceType;

  @Input({ required: true })
  selectedType?: ServiceType;

  @Output()
  onSelectedType = new EventEmitter<ServiceType | undefined>();
  isMobile: boolean = false;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait]).subscribe(result => {
      this.isMobile = result.matches;
    });
    iconRegistry.addSvgIconLiteral('truck', sanitizer.bypassSecurityTrustHtml(TRUCK_ICON));
    iconRegistry.addSvgIconLiteral('terminal_in', sanitizer.bypassSecurityTrustHtml(TERMINAL_IN_ICON));
    iconRegistry.addSvgIconLiteral('terminal_out', sanitizer.bypassSecurityTrustHtml(TERMINAL_OUT_ICON));
    iconRegistry.addSvgIconLiteral('truck_plus', sanitizer.bypassSecurityTrustHtml(TRUCK_PLUS_ICON));
    iconRegistry.addSvgIconLiteral('container_rent', sanitizer.bypassSecurityTrustHtml(CONTAINER_RENT_ICON));
    iconRegistry.addSvgIconLiteral('all', sanitizer.bypassSecurityTrustHtml(ALL_ICON));
  }

  ngOnInit(): void {}

  private lastValue?: String;

  onValueChange(value?: any) {
    if (this.lastValue == value) return;
    this.lastValue = value;

    if (value != 'ALL') {
      let newValue = ServiceType[value as keyof typeof ServiceType];
      this.onSelectedType.emit(newValue);
    } else {
      this.onSelectedType.emit(undefined);
    }
  }

  get serviceForTabs(): String {
    if (this.selectedType !== undefined) {
      return ServiceType[this.selectedType];
    } else {
      return 'ALL';
    }
  }
}
