import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteAutocompleteComponent } from '@common/route-autocomplete/route-autocomplete.component';
import { MatIconModule } from '@angular/material/icon';
import { ContainerTypeSelectComponent } from '@common/container-type-select/container-type-select.component';
import { MatButtonModule } from '@angular/material/button';
import { AutoOutput } from '@gen/common/Autocomplete';
import { Stations } from '@dto/stations';
import { ServiceType } from '@dto/service-type';
import { SelectedContainerFilter } from '@common/container-type-select/selected-container-filter';
import { SearchFilters } from '@common/routs-search-form/search-filters';

@Component({
  selector: 'app-service-routs-form',
  standalone: true,
  imports: [
    CommonModule,
    RouteAutocompleteComponent,
    MatIconModule,
    ContainerTypeSelectComponent,
    MatButtonModule,
  ],
  templateUrl: './service-routs-form.component.html',
  styleUrls: ['./service-routs-form.component.css'],
})
export class ServiceRoutsFormComponent {
  @Input()
  searchFilter!: SearchFilters;

  @Output()
  onFilter = new EventEmitter<undefined>();

  onDestinationSelected(value: AutoOutput | undefined) {
    let stations = value as Stations;
    if (value) {
      value.id = +stations.kladr!!;
    }

    this.searchFilter.destination = value;
  }

  swapLocations() {
    let destination = this.searchFilter.destination;
    this.searchFilter.destination = this.searchFilter.departure;
    this.searchFilter.departure = destination;

    this.applyFilter();
  }

  computeSelected(): SelectedContainerFilter {
    let filter = new SelectedContainerFilter();
    filter.size = this.searchFilter.containerSize;
    filter.type = this.searchFilter.containerType;
    return filter;
  }

  onDepartureSelected(value: AutoOutput | undefined) {
    let stations = value as Stations;
    if (value) {
      value.id = +stations.kladr!!;
    }

    this.searchFilter.departure = value;
  }

  selectServiceType(serviceType: ServiceType | undefined) {
    this.searchFilter.serviceType = serviceType;
    this.applyFilter();
  }

  onContainerTypeChange(changes: SelectedContainerFilter) {
    if (this.searchFilter) {
      this.searchFilter.containerSize = changes.size;
      this.searchFilter.containerType = changes.type;
    }
  }

  applyFilter() {
    this.onFilter.emit();
  }
}
