import { Component, effect, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form/login-form.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    LoginFormComponent,
    RegistrationFormComponent,
    MatTabsModule,
    ResetPasswordFormComponent,
  ],
})
export class LoginRegisterComponent {
  showResetPassword = signal<boolean>(false);

  @ViewChild('matTabGroup')
  tabGroup!: MatTabGroup;

  constructor() {
    effect(() => {
      if (this.showResetPassword()) {
        if (this.tabGroup) this.tabGroup.selectedIndex = 2;
      } else {
        if (this.tabGroup) this.tabGroup.selectedIndex = 0;
      }
    });
  }
}
