<div class="about-container">
  <h1>Сервис ISZ - это возможность находить актуальные заявки контейнерных перевозок быстро и удобно.</h1>
  <p>
    Мы собрали компании в едином пространстве, чтобы Вы могли находить клиентов и предлагать свои услуги.
    Будьте в рынке!
  </p>
  <div class="space__95"></div>
  <h2>На нашем сайте представлены следующие услуги:</h2>
  <div class="interactive-center">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="node" >
        <div class="node-icon">
          <img [src]="'/assets/images/icons/icon_truck.svg'" alt="Node Icon" />
        </div>
        <div class="node-content">
          <h3>Автотранспорт</h3>
          <p>Доставка контейнеров до выбранного терминала посредством автомобиля</p>
        </div>
      </div>
      <div class="node" >
        <div class="node-icon">
          <img [src]="'/assets/images/icons/icon_out.svg'" alt="Node Icon" />
        </div>
        <div class="node-content">
          <h3>Терминал на отправку</h3>
          <p>Отправление транспортированных контейнеров в пункт назначения</p>
        </div>
      </div>
    </div>
    <div class="central-circle-wrap d-flex justify-content-center">
      <div class="central-circle">
        <img style="width: 120px;" src="/assets/images/logo.svg" alt="ICZ"/>
      </div>
    </div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="node" >
        <div class="node-icon">
          <img [src]="'/assets/images/icons/icon_in.svg'" alt="Node Icon" />
        </div>
        <div class="node-content">
          <h3>Терминал на прием</h3>
          <p>Погрузка, отгрузка и буферное накопление контейнеров  в пунктах приема</p>
        </div>
      </div>
      <div class="node" >
        <div class="node-icon">
          <img [src]="'/assets/images/icons/icon_truck_plus.svg'" alt="Node Icon" />
        </div>
        <div class="node-content">
          <h3>Авто и Терминал</h3>
          <p>Полный пакет услуг с транспортировкой до выбранных пунктов назначения и отправления с последующей погрузкой и отгрузкой</p>
        </div>
      </div>
    </div>


    <img src="/assets/images/line.svg" class="line normal" />
    <img src="/assets/images/line.svg" class="line vertical-mirror" />
    <img src="/assets/images/line.svg" class="line horizontal-mirror" />
    <img src="/assets/images/line.svg" class="line both-mirror" />
  </div>

  <h2>Почему наш сервис поможет вам?</h2>
  <div class="about__card-container">
    <div class="about__card" *ngFor="let card of cards">
      <img [src]="card.icon" alt="Icon" class="about__card-icon" />
      <h3>{{ card.header }}</h3>
      <p>{{ card.text }}</p>
    </div>
  </div>
</div>
