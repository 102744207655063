import { Pipe, PipeTransform } from '@angular/core';
import { ContainerSize } from '@dto/container-size';

@Pipe({
  name: 'sizeToCyrillic',
  standalone: true,
})
export class ContaierSizeToCyrillicPipe implements PipeTransform {
  transform(value?: ContainerSize, ...args: string[]): string {
    switch (value) {
      case ContainerSize.FT20:
        return '20ft';
      case ContainerSize.FT40:
        return '40ft';
      default:
        return '';
    }
  }
}
