import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InRoleDirective } from '@cms/directives/InRoleDirective';
import { MatCardModule } from '@angular/material/card';
import { ContaierSizeToCyrillicPipe } from '../../../components/pipes/contaier-size-to-cyrillic.pipe';
import { ContainerType } from '@dto/container-type';
import { ContactsCellComponent } from '../../contacts-cell/contacts-cell.component';
import { PublicServiceView } from '../public-service.view';
import { LoginRegisterComponent } from '../../../login-register/login-register.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mobile-card',
  standalone: true,
  imports: [CommonModule, ContaierSizeToCyrillicPipe, InRoleDirective, MatCardModule, ContactsCellComponent],
  templateUrl: './mobile-card.component.html',
  styleUrls: ['./mobile-card.component.scss'],
})
export class MobileCardComponent {
  ContainerTypes = ContainerType;

  @Input()
  view!: PublicServiceView;

  openLoginDialog(): void {
    this.dialog.open(LoginRegisterComponent, {
      width: '450px',
      position: { top: '25vh' },
    });
  }

  constructor(public dialog: MatDialog) {}
}
