import { ErrorStateMatcher } from '@angular/material/core';
import { AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';

export class PassErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
    let newVar = !!(
      (control && (control.dirty || control.touched) && control.invalid) ||
      (form && form.invalid && form.hasError('passNotEquals'))
    );
    return newVar;
  }
}
