import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AutocompleteService } from '@cms/services/autocomplete.service';
import { UnsubscribeOnDestroyAdapter } from '@gen/common/UnsubscribeOnDestroyAdapter';
import { Autocomplete, AutoOutput } from '@gen/common/Autocomplete';
import { ExternalStateMatcher } from '@cms/common/external-state.matcher';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-route-autocomplete',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    NgFor,
    AsyncPipe,
    NgIf,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './route-autocomplete.component.html',
  styleUrls: ['./route-autocomplete.component.css'],
})
export class RouteAutocompleteComponent extends UnsubscribeOnDestroyAdapter {
  @ViewChild('input')
  input!: ElementRef<HTMLInputElement>;
  filteredOptions: AutoOutput[] = [];

  stateMatcher = new ExternalStateMatcher(() => this.errorState);

  @Input()
  errorState: boolean = false;

  autocomplete!: Autocomplete;

  @Input({ required: true })
  label: string = '';

  @Input({ required: true })
  set selectedValue(value: AutoOutput | undefined) {
    if (value) {
      this.autocomplete.fetch(value).subscribe(el => {
        this.formGroup.controls['formControl'].patchValue(el.value);
      });
    } else {
      this.formGroup.controls['formControl'].reset();
    }
  }

  formGroup = new FormGroup({
    formControl: new UntypedFormControl(),
  });

  @Output()
  onValue = new EventEmitter<AutoOutput | undefined>();

  constructor(autocompleteService: AutocompleteService) {
    super();

    this.autocomplete = autocompleteService.forStations();
    //do not subscribe in constructor cos component created on app start
  }

  ngOnInit() {
    this.subs.sink = this.autocomplete.subscribe(values => {
      this.filteredOptions = values;
    });
  }

  filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    if (filterValue == '' && this.formGroup.controls['formControl'].value) {
      this.onValue.next(undefined);
    } else {
      this.autocomplete.next(filterValue);
    }
  }

  onSelected($event: MatAutocompleteSelectedEvent) {
    this.onValue.next($event.option.value);
  }

  clearSelections() {
    this.onValue.next(undefined);
  }
}
