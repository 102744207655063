import {environment} from "@env"
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs"
import {map} from "rxjs/operators"
import {SearchCriteriaDTO} from "@dto/search-criteria-dto"
        

export abstract class RestClientService<T extends Object> {
    protected readonly apiUrl: string

    protected constructor (protected httpClient : HttpClient) {
        this.apiUrl = environment.apiUrl + "/" + this.getReqPath()
    }

    public getAll (searchcriteria: SearchCriteriaDTO): Observable<Page<T>> {
        return this.httpClient.post<RestResponseWrapper<Page<T>>>(
            this.apiUrl + "/search",
            searchcriteria
        )
            .pipe(
                map(unwrapResponse),
                map(el => {
                    el.content = el.content.map(content => {
                        return this.getGenericType().prototype.prepare.apply(content)
                    })
                    return el
                })
        )
    }

    public getOne (searchcriteria: SearchCriteriaDTO): Observable<T> {
        return this.httpClient.post<RestResponseWrapper<T>>(this.apiUrl + "/", searchcriteria)
            .pipe(
                map(unwrapResponse),
                map(el => {
                    return this.getGenericType().prototype.prepare.apply(el)
                })
        )
    }

    public save (entity: T): Observable<T> {
        return this.httpClient.post<RestResponseWrapper<T>>(this.apiUrl + "/save", entity)
            .pipe(map(unwrapResponse),
                map(el => {
                    return this.getGenericType().prototype.prepare.apply(el)
                }))
    }


    protected abstract getReqPath(): string

    protected abstract getGenericType(): any
}

export function unwrapResponse<T>(el: RestResponseWrapper<T>): T {
    if (el.isError) {
        throw el.content
    }
    // @ts-ignore
    return el.content
}

export class Page<T> {
    content: Array<T> = []
    empty: boolean = false
    first: boolean = false
    last: boolean = false
    number: number = 0
    numberOfElements: number = 0
    pageable: Pageable = new Pageable()
    size: number = 0
    sort: Sort = new Sort()
    totalElements: number = 0
    totalPages: number = 0
}

export class Pageable {
    offset: number = 0
    pageNumber: number = 0
    pageSize: number = 0
    paged: boolean = false
    sort: Sort = new Sort()
    unpaged: boolean = false
}

export class Sort {
    empty: boolean = false
    sorted: boolean = false
    unsorted: boolean = false
}

export class RestResponseWrapper<T> {
    // @ts-ignore
    content: Page<T> | T | ErrorData
    isError: boolean = false
}


export class ErrorData {
    // @ts-ignore
    code: string
    data : any
}