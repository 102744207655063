import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.css'],
  standalone: true,
  imports: [NgIf, RouterLink, MatButtonModule],
})
export class CookieConsentComponent implements OnInit {
  cookieConsentGiven: boolean = false;

  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {
    this.checkConsent();
  }

  checkConsent() {
    this.cookieConsentGiven = this.cookieService.check('cookieConsent');
  }

  agree() {
    this.cookieService.set('cookieConsent', 'true', { expires: 365 });
    this.cookieConsentGiven = true;
  }
}
