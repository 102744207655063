import { inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@env';
import { UserTokenService } from '@cms/services/user-token.service';

export const authInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const securityService = inject(UserTokenService);
  const router = inject(Router);

  let httpRequest = request;

  if (!httpRequest.url.startsWith(environment.backend)) {
    return next(httpRequest);
  }

  if (securityService) {
    httpRequest = httpRequest.clone({
      setHeaders: {
        Authorization: securityService.getToken(),
      },
    });
  }

  return next(httpRequest).pipe(
    catchError((err, caught) => {
      if (err instanceof HttpErrorResponse && err.status === 403) {
        if (securityService) {
          securityService.afterAuthRedirectUrl = window.location.pathname;
        }
        router.navigate(['/login']);
        return EMPTY;
      }
      return throwError(err);
    }),
    map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (
          event.url &&
          (event.url.includes('login') ||
            event.url.includes('registration') ||
            event.url.includes('/forget-pass/'))
        ) {
          let authorization = event.headers.get('Authorization');
          if (authorization) {
            securityService.setToken(authorization);
          }
        }
      }
      return event;
    })
  );
};
