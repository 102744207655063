import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginRegisterComponent } from '../../login-register/login-register.component';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SecurityService } from '@cms/services/security.service';
import { LkCompanyInfoDTOApi } from '@api/lk-company-info-dto.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';
import { InRoleDirective } from '@cms/directives/InRoleDirective';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ProfileButtonComponent } from './profile-button/profile-button.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    NgIf,
    AsyncPipe,
    InRoleDirective,
    ProfileButtonComponent,
  ],
})
export class HeaderComponent {
  isMobile: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }
}
