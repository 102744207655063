<div class="h1__container">
  <h1>Сервис по поиску контейнерных железнодорожных перевозок</h1>
  <p>
    Мы объединили транспортные компании в единой платформе,<br />
    чтобы вы могли легко находить новых клиентов и предлагать свои услуги.
  </p>
</div>
<div class="hero_image">
  <img ngSrc="/assets/images/hero.png" alt="Builder" width="300" height="300" />
</div>
