import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-success-bill-created-dialog',
  template: `
    <div class="custom-dialog">
      <mat-dialog-content class="dialog-content">
        <p>Счет сформирован и отправлен на почту!</p>
      </mat-dialog-content>
      <mat-dialog-actions class="dialog-actions">
        <button mat-raised-button color="primary" (click)="navigateToLk()">К списку счетов</button>
      </mat-dialog-actions>
    </div>
  `,
  styles: [
    `
      .custom-dialog {
        width: 415px;
        height: 280px;
        background: linear-gradient(180deg, #ececec 0%, #efefef 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .dialog-content,
      .dialog-actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .dialog-content {
        flex: 1;
        text-align: center;
        padding: 24px;
      }

      .dialog-actions {
        margin-bottom: 20px;
      }
    `,
  ],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class SuccessDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<SuccessDialogComponent>,
    private router: Router
  ) {}

  navigateToLk() {
    this.dialogRef.close();
    this.router.navigate(['/lk/issued-invoices']);
  }
}
