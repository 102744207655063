<div class="dialog-container">
  <h2 mat-dialog-title>Вы уверены, что хотите удалить менеджера?</h2>
  <div mat-dialog-content class="dialog-content">
    <p>Услуги, привязанные к менеджеру, будут сняты с публикации.</p>
  </div>
  <div mat-dialog-action class="dialog-actions">
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Отмена</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">Удалить</button>
  </div>
</div>
