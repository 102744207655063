<mat-form-field class="w-100" #parent [formGroup]="formGroup">
  <mat-label>Тип/размер контейнера</mat-label>
  <input
    readonly
    type="text"
    matInput
    [errorStateMatcher]="stateMatcher"
    formControlName="formControl"
    (click)="showOptions()"
  />
  <mat-error *ngIf="errorState[0]"> Выберите тип контейнера</mat-error>
  <mat-error *ngIf="errorState[1]"> Выберите размер контейнера</mat-error>
  <button
    *ngIf="computeSelected().length > 0"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="clearSelections($event)"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<ng-template cdk-portal class="dropdown">
  <div class="border border-secondary bg-light corner"></div>
  <div
    class="w-100 bg-light d-flex border border-secondary rounded"
    style="padding-top: 30px; padding-left: 15px; padding-right: 15px"
  >
    <div class="d-flex flex-column w-50">
      <label id="size-selector-label" class="font-weight-bold">Размер</label>
      <mat-radio-group
        [ngModel]="selectedSize()"
        (change)="sizeChanges($event)"
        class="d-flex flex-column"
        aria-labelledby="size-selector-label"
      >
        <mat-radio-button *ngFor="let el of containerSizes" [value]="el.value">{{
          el.viewValue
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="d-flex flex-column w-50">
      <label id="type-selector-label" class="font-weight-bold">Принадлежность</label>
      <mat-radio-group
        (change)="typeChanges($event)"
        [ngModel]="selectedType()"
        class="d-flex flex-column"
        aria-labelledby="type-selector-label"
      >
        <mat-radio-button *ngFor="let el of containerTypes" [value]="el.value">{{
          el.viewValue
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</ng-template>
<ng-content></ng-content>
