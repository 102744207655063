import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CMSFormControll, createForm } from '@gen/common/CMSForms';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { emailRegexp } from '@cms/common/ErrorHelper';
import { equalsPasswordValidator } from '@cms/common/EqualsPasswordValidator';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { PassErrorStateMatcher } from '@cms/common/pass-error-state.matcher';
import { LoginForm } from '../login-form/login-form.component';
import { SecurityApi } from '@api/security-api';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecurityService } from '@cms/services/security.service';

@Component({
  selector: 'app-registration-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    RouterLink,
  ],
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.css'],
})
export class RegistrationFormComponent {
  public passErrorStateMatcher = new PassErrorStateMatcher();
  public registrationForm!: RegistrationForm;

  constructor(
    private securityService: SecurityService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.registrationForm = createForm(RegistrationForm);
    this.registrationForm.username.addValidators([Validators.required, Validators.pattern(emailRegexp)]);
    this.registrationForm.password.addValidators([Validators.required, Validators.minLength(7)]);
    this.registrationForm.password2.addValidators([Validators.required, Validators.minLength(7)]);
    this.registrationForm.checkBox.addValidators([Validators.requiredTrue]);
    this.registrationForm.addValidators(equalsPasswordValidator());
  }

  doRegister() {
    this.registrationForm.markAllAsTouched();
    if (this.registrationForm.invalid) return;

    this.securityService
      .registration(this.registrationForm.username.value, this.registrationForm.password.value)
      .pipe(
        catchError(err => {
          if (err.code == 'AlreadyExistsException') {
            this.snackBar.open('Данный пользователь уже зарегистрирован', 'Close', {
              duration: 3000,
            });
            this.registrationForm.reset();
            return EMPTY;
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe(userInfo => {
        this.dialog.closeAll();

        const dialogRef = this.dialog.open(SuccessDialogComponent, { data: 'Благодарим за регистрацию!' });

        dialogRef.afterClosed().subscribe(() => {});
      });
  }
}

class RegistrationForm extends LoginForm {
  password2 = new CMSFormControll();

  checkBox = new CMSFormControll();
}
