import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {Page, RestClientService, RestResponseWrapper, unwrapResponse} from "./rest-client.service";

import {ServicePublicDTO} from "../dto/service-public-dto";



@Injectable({
    providedIn: 'root'
})
export class ServicePublicDTOApi extends RestClientService<ServicePublicDTO> {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected getReqPath(): string {
        return "service-public-dto";
    }

    protected getGenericType(): any {
        return ServicePublicDTO;
    }

}