<div class="about-container">
  <h1>Сервис ISZ - это возможность находить актуальные заявки контейнерных перевозок быстро и удобно.</h1>
  <p>
    Мы собрали компании в едином пространстве, чтобы Вы могли находить клиентов и предлагать свои услуги.
    Будьте в рынке!
  </p>
  <div class="space__95"></div>
  <h2>На нашем сайте представлены следующие услуги:</h2>
  <div class="interactive-center">
    <div class="central-circle">
      <img style="width: 120px;" src="/assets/images/logo.svg" alt="ICZ"/>
    </div>
    <div *ngFor="let node of nodes" class="node" [ngStyle]="node.position">
      <div class="node-icon">
        <img [src]="node.icon" alt="Node Icon" />
      </div>
      <div class="node-content">
        <h3>{{ node.title }}</h3>
        <p>{{ node.description }}</p>
      </div>
    </div>
    <img src="/assets/images/line.svg" class="line normal" />
    <img src="/assets/images/line.svg" class="line vertical-mirror" />
    <img src="/assets/images/line.svg" class="line horizontal-mirror" />
    <img src="/assets/images/line.svg" class="line both-mirror" />
  </div>

  <h2>Почему наш сервис поможет вам?</h2>
  <div class="about__card-container">
    <div class="about__card" *ngFor="let card of cards">
      <img [src]="card.icon" alt="Icon" class="about__card-icon" />
      <h3>{{ card.header }}</h3>
      <p>{{ card.text }}</p>
    </div>
  </div>
</div>
