<div class="empty-state">
  <div class="svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="878" height="650" viewBox="0 0 878 650" fill="none">
      <path
        d="M652.728 10.5359C879.323 -34.5668 902.387 132.511 833.967 253.085C798.78 323.435 908.21 369.635 864.538 456.784C820.866 543.934 683.298 439.46 612.695 572.809C542.091 706.159 226.196 641.059 222.556 524.509C218.917 407.96 165.782 439.984 44.2278 323.435C-77.3266 206.885 91.5396 10.5362 269.868 59.361C448.196 108.186 407.435 59.3604 652.728 10.5359Z"
        stroke="#94AFA9"
        stroke-opacity="0.1"
        stroke-width="5"
      />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="813" height="603" viewBox="0 0 813 603" fill="none">
      <path
        d="M605.075 9.90306C812.889 -31.5164 837.005 122.38 773.498 233.624C740.296 299.121 840.543 342.723 800.317 422.939C759.192 504.945 633.448 409.035 567.585 531.388C501.347 654.021 208.878 595.668 205.572 486.248C202.265 377.469 151.994 407.432 40.6804 299.398C-70.6335 191.365 84.2837 10.5862 250.26 54.9123C413.756 99.2384 378.97 55.0158 605.075 9.90306Z"
        stroke="#94AFA9"
        stroke-opacity="0.1"
        stroke-width="5"
      />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="748" height="559" viewBox="0 0 748 559" fill="none">
      <path
        d="M557.42 9.30472C746.456 -28.6384 771.619 112.846 713.026 215.317C681.809 276.292 772.875 317.518 736.095 391.201C697.518 468.478 583.597 380.654 522.474 492.621C460.604 605.15 191.561 553.257 188.588 450.414C185.615 348.857 138.209 376.908 37.1342 276.848C-63.9409 176.788 77.0292 10.6761 230.652 50.7235C379.318 90.771 350.503 50.9318 557.42 9.30472Z"
        stroke="#94AFA9"
        stroke-opacity="0.1"
        stroke-width="5"
      />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="685" height="511" viewBox="0 0 685 511" fill="none">
      <path
        d="M511.261 8.66085C682.024 -25.5311 708.307 102.518 654.469 195.474C625.153 251.484 707.281 290.035 673.849 356.653C637.714 428.642 535.311 349.54 478.764 450.31C421.094 551.92 174.752 506.864 172.104 411.341C169.456 317.741 124.787 343.677 33.68 252.315C-57.4271 160.953 69.974 10.7108 211.658 46.1893C345.892 81.6677 322.974 46.5009 511.261 8.66085Z"
        stroke="#94AFA9"
        stroke-opacity="0.1"
        stroke-width="5"
      />
    </svg>
  </div>
  <img src="/assets/images/empty_routes.png" alt="Empty Table" class="empty-image" />
  <p>
    Упс...Маршрут не найден.<br />
    Попробуйте другие параметры поиска.
  </p>
</div>
